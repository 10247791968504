import PropTypes from 'prop-types'

import LogoutDialog from './LogoutDialog'
import SalesRestoreDialog, { SalesResetAgreementDialog } from './SalesRestoreDialog'
import UserGuideChooseMergeDialog from './UserGuideChooseMergeDialog'
import UserGuideChooseMergeNextDialog from './UserGuideChooseMergeNextDialog'
import UserGuideMergeSuccessDialog from './UserGuideMergeSuccessDialog'
import UserGuideWarnDialog from './UserGuideWarnDialog'

const HeaderDialogs = props => {
  const { dialogType, ...rest } = props

  const Dialogs = {
    logout: <LogoutDialog {...rest} />,
    salesRestore: <SalesRestoreDialog {...rest} />,
    salesReset: <SalesResetAgreementDialog {...rest} />,
    userGuideWarn: <UserGuideWarnDialog {...rest} />,
    userGuideChooseMerge: <UserGuideChooseMergeDialog {...rest} />,
    userGuideMergeSuccess: <UserGuideMergeSuccessDialog {...rest} />,
    userGuideChooseMergeNext: <UserGuideChooseMergeNextDialog {...rest} />
  }

  if (!Object.keys(Dialogs).includes(dialogType)) {
    console.warn('Please check dialogType Dialogs', dialogType)
  }

  return Dialogs[dialogType] ?? null
}

HeaderDialogs.propTypes = {
  dialogType: PropTypes.string
}

export default HeaderDialogs
