import classNames from 'clsx'
import PropTypes from 'prop-types'

import { forwardRef } from 'react'

// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles'
import { Close } from '@material-ui/icons'

// CSS IN JS
import dialogContentStyle from '@Common/jss/components/dialogContentStyle'

// Custom Components
import Button from '../CustomButtons/Button'

const useStyles = makeStyles(dialogContentStyle)

const DialogContent = forwardRef((props, ref) => {
  const {
    isGreen = false,
    titleId,
    // 用来区分title相同的dialog
    uniqueId = '',
    confirmText,
    cancelText,
    dialogTitle = '',
    gaTitle = '',
    subtitle,
    dialogContent,
    cancel,
    autoFocus,
    closeHandle,
    confirmHandle,
    disabled,
    highlightButton,
    showClose,
    confirm = true,
    width,
    maxWidth,
    centerText = false,
    centerAction = false,
    confirmColor,
    actions
  } = props
  const classes = useStyles()
  const title = typeof dialogTitle === 'string' ? dialogTitle?.replace(/\s*/g, '') : gaTitle?.replace(/\s*/g, '')
  console.log('dialogTitle', dialogTitle)
  const style = () => {
    const styleDialog = {}
    width && Object.assign(styleDialog, { width })
    maxWidth && Object.assign(styleDialog, { maxWidth })
    return styleDialog
  }

  const handleConfirm = () => {
    /* 传递空event给方法 */
    confirmHandle()
  }

  return (
    // <div className={classes.dialog} style={width && { width: width }  maxWidth && {maxWidth: maxWidth}}>
    <div
      ref={ref}
      style={style()}
      className={classNames({
        [classes.dialog]: true,
        [classes.greenLawn]: isGreen
      })}
    >
      <div
        className={classes.title}
        id={titleId}
      >
        {dialogTitle}
        {showClose && (
          <Button
            className={classes.closeBtn}
            color="transparent"
            id={`btnClose_${title}_${uniqueId}`}
            justIcon
            onClick={closeHandle}
          >
            <Close className={classes.close} />
          </Button>
        )}
      </div>
      {subtitle && <div className={classes.subtitle}>{subtitle}</div>}
      <div
        className={classNames({
          [classes.content]: true,
          [classes.centerText]: centerText
        })}
      >
        {dialogContent}
      </div>
      {actions}
      <div
        className={classNames({
          [classes.actions]: true,
          [classes.centerAction]: centerAction
        })}
      >
        {cancel && (
          <Button
            className={classes.cancel}
            color="dTen"
            id={`btnCancel${title}_${cancelText}_${uniqueId}`}
            size={'sm'}
            onClick={closeHandle}
          >
            {cancelText}
          </Button>
        )}
        {confirm && (
          <Button
            autoFocus={autoFocus}
            color={confirmColor}
            disabled={disabled}
            id={`btnConfirm${title}_${confirmText}_${uniqueId}`}
            size={'sm'}
            className={classNames({
              [classes.save]: true,
              [classes.highlightButton]: highlightButton
            })}
            onClick={handleConfirm}
          >
            {confirmText}
          </Button>
        )}
      </div>
    </div>
  )
})

DialogContent.displayName = 'DialogContent'

DialogContent.defaultProps = {
  isGreen: false,
  cancelText: 'No',
  confirmText: 'Yes',
  cancel: false,
  autoFocus: true,
  highlightButton: false,
  confirmColor: 'success'
}
DialogContent.propTypes = {
  isGreen: PropTypes.bool,
  titleId: PropTypes.string,
  uniqueId: PropTypes.string,
  gaTitle: PropTypes.string,
  cancelText: PropTypes.node,
  confirmText: PropTypes.node,
  dialogContent: PropTypes.node,
  dialogTitle: PropTypes.any,
  subtitle: PropTypes.string,
  actions: PropTypes.node,
  cancel: PropTypes.bool,
  autoFocus: PropTypes.bool,
  closeHandle: PropTypes.func.isRequired,
  confirmHandle: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  highlightButton: PropTypes.bool,
  showClose: PropTypes.bool,
  confirm: PropTypes.bool,
  centerText: PropTypes.bool,
  centerAction: PropTypes.bool,
  maxWidth: PropTypes.string,
  width: PropTypes.string,
  confirmColor: PropTypes.string
}

export default DialogContent
