import { DISPLAY_HEALTHCHECK } from '@Constants'

import { lazy } from 'react'

import DetailsLayout from '@Layouts/DetailsLayout'
import MembersLayout from '@Layouts/MembersLayout'
import TicketDetailsLayout from '@Layouts/TicketDetailsLayout'

const Dashboard = lazy(() => import('@Views/Admin/Dashboard/Dashboard'))
const Usage = lazy(() => import('@Views/Admin/Dashboard/Usage/Usage'))
const DeviceManagement = lazy(() => import('@Views/Admin/DeviceManagement/DeviceManagement'))

const AccountManagement = lazy(() => import('@Views/Admin/AccountManagement/AccountManagement'))
const AuthorizationManage = lazy(() => import('@Views/Admin/DeviceManagement/AuthorizationManage'))
const Billing = lazy(() => import('@Views/Admin/Billing/Billing'))
const Details = lazy(() => import('@Views/Admin/Details/Details'))
const Profile = lazy(() => import('@Views/Admin/Profile/Profile'))
const Equipment = lazy(() => import('@Views/Admin/Equipment/Equipment'))
const Members = lazy(() => import('@Views/Admin/Members/Members'))
const Authority = lazy(() => import('@Views/Admin/Authority'))
const Account = lazy(() => import('@/Views/Admin/Account/Account'))
const Support = lazy(() => import('@Views/Admin/Support/Tickets'))
const TicketDetails = lazy(() => import('@Views/Admin/Support/Detail'))

const Applications = lazy(() => import('@Views/Admin/Applications'))
const SiemSettings = lazy(() => import('@Views/Admin/Siem/Settings'))
const AlertEvents = lazy(() => import('@Views/Admin/Siem/AlertEvents/index'))

const PartnerAccountProfile = lazy(() => import('@Views/Admin/Services/PartnerManagement/PartnerAccountProfile'))

const ProfileInvite = lazy(
  () => import('@/Views/Admin/Services/PartnerManagement/PartnerAccountProfile/ProfileInvite/ProfileInvite')
)

const ProfileStepResult = lazy(
  () =>
    import(
      '@/Views/Admin/Services/PartnerManagement/PartnerAccountProfile/ProfileInviteStepResult/ProfileInviteStepResult'
    )
)

const ProfileSearch = lazy(() => import('@/Views/Admin/Services/PartnerManagement/PartnerAccountProfile/ProfileSearch'))

const PartnerActivity = lazy(() => import('@Views/Admin/Services/PartnerManagement/PartnerActivity'))
const CustomerGroup = lazy(() => import('@Views/Admin/Services/CustomerGroup'))

const PartnerPermissions = lazy(() => import('@Views/Admin/Services/PartnerManagement/PartnerPermissions'))

const AgreementManagement = lazy(() => import('@Views/Admin/Services/AgreementManagement'))

const AgreementDetail = lazy(() => import('@Views/Admin/Services/AgreementManagement/Agreement/Detail'))

const AgreementChange = lazy(() => import('@Views/Admin/Services/AgreementManagement/Agreement/Detail/AgreementChange'))

// const Applications = React.lazy(() => import('../Applications'))
// const SingleSignOn = React.lazy(() => import('../Applications/SingleSignOn/SingleSignOn'))
// const Cubicle = React.lazy(() => import('../Applications/DtenCubicle/Cubicle'))
// const AppCollection = React.lazy(() => import('../Applications/AppCollection/AppCollection'))
let adminRoutes = [
  {
    key: 'dashboard',
    path: '/dashboard',
    component: Dashboard,
    authority: ['SuperAdmin', 'Owner', 'Admin']
  },
  {
    key: 'usage',
    path: '/dashboard/usage',
    component: Usage,
    authority: ['SuperAdmin', 'Owner', 'Admin']
  },
  {
    key: 'device',
    path: '/device',
    component: DeviceManagement,
    authority: ['SuperAdmin', 'Owner', 'Admin']
  },
  {
    key: 'deviceManage',
    path: '/device/manage',
    component: DeviceManagement,
    authority: ['SuperAdmin', 'Owner', 'Admin']
  },
  {
    key: 'deviceGroup',
    path: '/device/group',
    component: DeviceManagement,
    authority: ['SuperAdmin', 'Owner', 'Admin']
  },
  {
    key: 'manageAuthorization',
    path: '/device/manage-authorization',
    component: AuthorizationManage,
    authority: ['SuperAdmin', 'Owner', 'Admin']
  },
  {
    key: 'deviceUpgrade',
    path: '/device/update',
    component: DeviceManagement,
    authority: ['SuperAdmin', 'Owner', 'Admin']
  },
  {
    key: 'deviceManageSetting',
    path: '/device/setting',
    component: DeviceManagement,
    authority: ['SuperAdmin', 'Owner', 'Admin']
  },
  // maybe 已废弃 (需要确认)
  {
    key: 'userinfo',
    path: '/userinfo',
    component: AccountManagement,
    authority: ['SuperAdmin', 'Owner', 'Admin']
  },
  {
    key: 'members',
    path: '/members',
    component: Members,
    layout: MembersLayout,
    authority: ['SuperAdmin', 'Owner', 'Admin']
  },
  {
    key: 'membersUser',
    path: '/members/users',
    component: Members,
    layout: MembersLayout,
    authority: ['SuperAdmin', 'Owner', 'Admin']
  },
  {
    key: 'membersPending',
    path: '/members/pending',
    component: Members,
    layout: MembersLayout,
    authority: ['SuperAdmin', 'Owner', 'Admin']
  },
  {
    key: 'billing',
    path: '/billing',
    component: Billing,
    authority: ['SuperAdmin', 'Owner', 'Admin']
  },
  {
    key: 'authority',
    path: '/authority',
    component: Authority,
    authority: ['SuperAdmin', 'Owner', 'Admin']
  },
  {
    key: 'authorityCategory',
    path: '/authority/category',
    component: Authority,
    authority: ['SuperAdmin', 'Owner', 'Admin']
  },
  {
    key: 'authorityManage',
    path: '/authority/manage/:manageType',
    component: Authority,
    authority: ['SuperAdmin', 'Owner', 'Admin']
  },
  {
    key: 'account',
    path: '/account',
    component: Account,
    authority: ['SuperAdmin', 'Owner', 'Admin']
  },
  {
    key: 'customerGroup',
    path: '/service/customer-group',
    component: CustomerGroup,
    authority: ['SuperAdmin', 'Owner', 'Admin']
  },
  {
    key: 'partner',
    path: '/service/partner/profile',
    component: PartnerAccountProfile,
    authority: ['SuperAdmin', 'Owner', 'Admin']
  },
  {
    key: 'partner',
    path: '/service/partner/profile/search',
    component: ProfileSearch,
    authority: ['SuperAdmin', 'Owner', 'Admin']
  },
  {
    key: 'profileInvite',
    path: '/service/partner/profile/invite',
    component: ProfileInvite,
    authority: ['SuperAdmin', 'Owner', 'Admin']
  },
  {
    key: 'profileInviteResult',
    path: '/service/partner/profile/search/step/result',
    component: ProfileStepResult,
    authority: ['SuperAdmin', 'Owner', 'Admin']
  },
  {
    key: 'partner',
    path: '/service/partner/activity',
    component: PartnerActivity,
    authority: ['SuperAdmin', 'Owner', 'Admin']
  },
  {
    key: 'agreementManagement',
    path: '/service/agreement',
    component: AgreementManagement,
    authority: ['SuperAdmin', 'Owner', 'Admin']
  },
  {
    key: 'agreementManagementDetail',
    path: '/service/agreement/info',
    component: AgreementDetail,
    authority: ['SuperAdmin', 'Owner', 'Admin']
  },
  {
    key: 'agreementManagementDetailChange',
    path: '/service/agreement/change',
    component: AgreementChange,
    authority: ['SuperAdmin', 'Owner', 'Admin']
  },
  {
    key: 'partnerPermissions',
    path: '/service/partner/permission',
    component: PartnerPermissions,
    authority: ['SuperAdmin', 'Owner', 'Admin']
  },
  {
    key: 'profile',
    path: '/profile',
    component: Profile,
    authority: ['SuperAdmin', 'Owner', 'Admin', 'Member']
  },
  {
    key: 'equipment',
    path: '/equipment',
    component: Equipment,
    authority: ['SuperAdmin', 'Owner', 'Admin', 'Member']
  },
  {
    key: 'detail',
    path: '/detail',
    component: Details,
    layout: DetailsLayout,
    authority: ['SuperAdmin', 'Owner', 'Admin', 'Member']
  },
  {
    key: 'detailsProfile',
    path: '/detail/device-profile',
    component: Details,
    layout: DetailsLayout,
    authority: ['SuperAdmin', 'Owner', 'Admin', 'Member']
  },
  {
    key: 'detailsSettings',
    path: '/detail/settings',
    component: Details,
    layout: DetailsLayout,
    authority: ['SuperAdmin', 'Owner', 'Admin', 'Member']
  },
  {
    key: 'detailsHealthCheck',
    path: '/detail/health-check',
    component: Details,
    layout: DetailsLayout,
    authority: ['SuperAdmin', 'Owner', 'Admin', 'Member']
  },
  {
    key: 'support',
    path: '/support/tickets',
    component: Support,
    authority: ['SuperAdmin', 'Owner', 'Admin', 'Member']
  },
  {
    key: 'ticketsDetail',
    path: '/support/tickets/detail/:ticketId',
    component: TicketDetails,
    layout: TicketDetailsLayout,
    authority: ['SuperAdmin', 'Owner', 'Admin', 'Member']
  },
  {
    key: 'applications',
    path: '/applications*',
    component: Applications,
    authority: ['SuperAdmin', 'Owner', 'Admin', 'Member']
  },
  {
    key: 'siemAlert',
    path: '/siem/alert/config',
    component: SiemSettings,
    authority: ['SuperAdmin', 'Owner', 'Admin', 'Member']
  },
  {
    key: 'siemEvents',
    path: '/siem/alert/events',
    component: AlertEvents,
    authority: ['SuperAdmin', 'Owner', 'Admin', 'Member']
  }
  // {
  //   key: 'collection',
  //   path: '/applications/collection',
  //   component: AppCollection,
  //   authority: ['SuperAdmin', 'Owner', 'Admin', 'Member']
  // },
  // {
  //   key: 'singleSignOn',
  //   path: '/applications/sign-on',
  //   component: SingleSignOn,
  //   authority: ['SuperAdmin', 'Owner', 'Admin', 'Member']
  // },
  // {
  //   key: 'cubicle',
  //   path: '/applications/cubicle',
  //   component: Cubicle,
  //   authority: ['SuperAdmin', 'Owner', 'Admin', 'Member']
  // }
]

if (!DISPLAY_HEALTHCHECK) {
  adminRoutes = adminRoutes.filter(item => item.path !== '/detail/health-check')
}

export const setAdminRoutes = condition => {
  if (condition) {
    return adminRoutes.filter(route => route.authority.includes(condition))
  }
  return []
}
