import { useEffect, useState } from 'react'
import * as React from 'react'

import TextField from '@material-ui/core/TextField'

import { buildStyles, CircularProgressbar } from '@/Components/CirularProgressbar'
import FacebookCircularProgress from 'src/Components/FacebookCircularProgress/FacebookCircularProgress'

import CustomSelect from '../../../Components/Autocomplete/CustomSelect'
import data from '../../../Components/Autocomplete/data'
import DateRange from '../../../Components/DateRange/DateRange'
import Uploader from '../../../Components/FileUploader/Uploader'
import Pingone from './Pingone'

import '@Components/CirularProgressbar/styles.css'

const INIT_FILELISTS = [
  {
    uid: '-3',
    name: 'image.png',
    status: 'done',
    url: 'https://zos.alipayobjects.com/rmsportal/jkjgkEfvpUPVyRjUImniVslZfWPnJuuZ.png'
  },
  {
    uid: '-xxx',
    percent: 50,
    name: 'image.png',
    status: 'uploading',
    url: 'https://zos.alipayobjects.com/rmsportal/jkjgkEfvpUPVyRjUImniVslZfWPnJuuZ.png'
  },
  {
    uid: '-5',
    name: 'image.png',
    status: 'error'
  }
]

const FrontEndTest = () => {
  const [value, setValue] = React.useState([null, null])
  const [fileList, setFileList] = useState([])

  const onChangeHandle = newValue => {
    console.log(newValue)
    setValue(newValue)
  }

  useEffect(() => {
    setFileList(INIT_FILELISTS)
  }, [])

  const handleChange = (files, filesLists) => {
    console.log(filesLists)
    setFileList([...fileList, ...files])
  }
  const percentage = 80
  return (
    <div>
      FrontEndTest Component
      <div
        style={{
          width: 16,
          height: 16,
          marginBottom: 20
        }}
      >
        <CircularProgressbar
          background={true}
          backgroundPadding={1}
          strokeWidth={50}
          value={percentage}
          styles={buildStyles({
            strokeLinecap: 'butt',
            pathColor: '#4EB857',
            trailColor: '#FFF',
            backgroundColor: '#4EB857'
          })}
        />
      </div>
      <div
        style={{
          width: 16,
          height: 16,
          marginBottom: 20
        }}
      >
        <CircularProgressbar
          background={true}
          backgroundPadding={2}
          strokeWidth={50}
          value={percentage}
          styles={buildStyles({
            strokeLinecap: 'butt',
            pathColor: '#4EB857',
            trailColor: '#FFF',
            backgroundColor: '#4EB857'
          })}
        />
      </div>
      <div
        style={{
          width: 16,
          height: 16,
          marginBottom: 20
        }}
      >
        <CircularProgressbar
          background={true}
          backgroundPadding={4}
          strokeWidth={50}
          value={percentage}
          styles={buildStyles({
            strokeLinecap: 'butt',
            pathColor: '#4EB857',
            trailColor: '#FFF',
            backgroundColor: '#4EB857'
          })}
        />
      </div>
      <div>
        <FacebookCircularProgress
          size={16}
          thickness={4}
          value={20}
          variant="determinate"
        />
      </div>
      <CustomSelect
        getOptionLabel={option => option.areacode}
        options={data}
        renderInput={params => (
          <TextField
            {...params}
            label="Combo box"
            variant="outlined"
          />
        )}
      />
      <DateRange
        date={value}
        onChange={onChangeHandle}
      />
      <Uploader
        action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
        fileList={fileList}
        multiple
        onChange={handleChange}
      />
      <Pingone />
    </div>
  )
}

export default FrontEndTest
