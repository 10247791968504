import { useCallback, useReducer, useRef } from 'react'

import { validateDate } from '../Utils/date-utils'
import { useNow, useUtils } from './useUtils'

export const createCalendarStateReducer =
  (reduceAnimations, disableSwitchToMonthOnDayFocus, utils) => (state, action) => {
    switch (action.type) {
      case 'changeMonth':
        return {
          ...state,
          slideDirection: action.direction,
          currentMonth: action.newMonth,
          isMonthSwitchingAnimating: !reduceAnimations
        }

      case 'finishMonthSwitchingAnimation':
        return {
          ...state,
          isMonthSwitchingAnimating: false
        }

      case 'changeFocusedDay': {
        const needMonthSwitch =
          Boolean(action.focusedDay) &&
          !disableSwitchToMonthOnDayFocus &&
          !utils.isSameMonth(state.currentMonth, action.focusedDay)

        return {
          ...state,
          focusedDay: action.focusedDay,
          isMonthSwitchingAnimating: needMonthSwitch && !reduceAnimations,
          currentMonth: needMonthSwitch ? utils.startOfMonth(action.focusedDay) : state.currentMonth,
          slideDirection: utils.isAfterDay(action.focusedDay, state.currentMonth) ? 'left' : 'right'
        }
      }

      default:
        throw new Error('missing support')
    }
  }

/**
 * @Desc
 * @param date
 * @param defaultCalendarMonth
 * @param disableFuture
 * @param disablePast
 * @param disableSwitchToMonthOnDayFocus
 * @param maxDate
 * @param minDate
 * @param onMonthChange
 * @param reduceAnimations
 * @param shouldDisableDate
 * @returns {{changeMonth: (function(*): undefined), onMonthSwitchingAnimationEnd: (function(): void), handleChangeMonth: (function(*): void), isDateDisabled: (function(*=): boolean), calendarState: never, changeFocusedDay: (function(*=): void)}}
 */
export function useCalendarState({
  date,
  defaultCalendarMonth,
  disableFuture,
  disablePast,
  disableSwitchToMonthOnDayFocus = false,
  maxDate,
  minDate,
  onMonthChange,
  reduceAnimations,
  shouldDisableDate
}) {
  const now = useNow()
  const utils = useUtils()
  const reducerFn = useRef(
    createCalendarStateReducer(Boolean(reduceAnimations), disableSwitchToMonthOnDayFocus, utils)
  ).current

  const [calendarState, dispatch] = useReducer(reducerFn, {
    isMonthSwitchingAnimating: false,
    focusedDay: date,
    currentMonth: utils.startOfMonth(date ?? defaultCalendarMonth ?? now),
    slideDirection: 'left'
  })

  const handleChangeMonth = useCallback(
    payload => {
      dispatch({
        type: 'changeMonth',
        ...payload
      })

      if (onMonthChange) {
        onMonthChange(payload.newMonth)
      }
    },
    [onMonthChange]
  )

  const changeMonth = useCallback(
    newDate => {
      const newDateRequested = newDate ?? now
      if (utils.isSameMonth(newDateRequested, calendarState.currentMonth)) {
        return
      }

      handleChangeMonth({
        newMonth: utils.startOfMonth(newDateRequested),
        direction: utils.isAfterDay(newDateRequested, calendarState.currentMonth) ? 'left' : 'right'
      })
    },
    [calendarState.currentMonth, handleChangeMonth, now, utils]
  )

  const isDateDisabled = useCallback(
    day =>
      validateDate(utils, day, {
        disablePast,
        disableFuture,
        minDate,
        maxDate,
        shouldDisableDate
      }) !== null,
    [disableFuture, disablePast, maxDate, minDate, shouldDisableDate, utils]
  )

  const onMonthSwitchingAnimationEnd = useCallback(() => {
    dispatch({ type: 'finishMonthSwitchingAnimation' })
  }, [])

  const changeFocusedDay = useCallback(
    newFocusedDate => {
      if (!isDateDisabled(newFocusedDate)) {
        dispatch({ type: 'changeFocusedDay', focusedDay: newFocusedDate })
      }
    },
    [isDateDisabled]
  )

  return {
    calendarState,
    changeMonth,
    changeFocusedDay,
    isDateDisabled,
    onMonthSwitchingAnimationEnd,
    handleChangeMonth
  }
}
