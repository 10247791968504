import dayjs from 'dayjs'
import localizedFormat from 'dayjs/plugin/localizedFormat'
import utc from 'dayjs/plugin/utc'

dayjs.extend(localizedFormat)
dayjs.extend(utc)

/**
 * @description: 时间格式化本地时间
 * 默认当地时间
   dayjs().format() //2019-03-06T17:11:55+08:00
 * @param {*} date
 * @param {*} format
 * @return {*}
 */
export const formatDate = (date, format = 'LL') => {
  if (!dayjs(date).isValid()) {
    return ''
  }
  return dayjs(date).format(format)
}

export const formatDate2UTC = (date, format) => {
  if (!dayjs(date).isValid()) {
    return ''
  }
  format = format || 'LL'
  return dayjs(date).utc().format(format)
}

/**
 * 默认情况下，Day.js 会把时间解析成本地时间。
 * 如果想使用 UTC 时间，您可以调用 dayjs.utc() 而不是 dayjs()
 * @description: 格林威治时间转当地时间
 * @param {*} greenwichDate '2022-08-08 10:21:11' 或者 1659925678357
 * @param {*} format 'YYYY-MM-DD HH:mm:ss'
 * @return {*}
 */
export const formatDateByTimezone = (greenwichDate, format) => {
  if (typeof greenwichDate === 'string') {
    greenwichDate = greenwichDate.replace(/-/g, '/')
  }
  if (!dayjs(greenwichDate).isValid()) {
    return ''
  }

  const offset = new Date().getTimezoneOffset() * 60 * 1000

  if (typeof greenwichDate === 'number') {
    const dateTime = greenwichDate - offset
    return dayjs(dateTime).utc().format(format)
  } else {
    const dateTime = new Date(greenwichDate) - offset
    return dayjs(dateTime).format(format)
  }
}

/**
 * @description: 格林威治时间转GMT 对应的 当地时间
 * @param {*} greenwichDate  '2022-08-08 10:21:11' 或者 1659925678357 时间戳
 * @param {*} format 'YYYY-MM-DD HH:mm:ss'
 * @param {*} GMTOffset 'GMT-09:00'
 *
 * 时间段为 2022-09-26 00:00:00 --- 2022-09-30 23:59:59
 * 需要显示为 2022-09-26 --- 2022-09-30
 * @return {*}
 */
export const formatDateByTimezoneGTM = (greenwichDate, format, GMTOffset) => {
  if (typeof greenwichDate === 'string') {
    greenwichDate = greenwichDate.replace(/-/g, '/')
  }
  if (!dayjs(greenwichDate).isValid()) {
    return ''
  }

  const GMTOffsetHourMinute = GMTOffset.replace(/GMT/, '').replace(/-/, '').replace(/\+/, '')

  const hour = parseInt(GMTOffsetHourMinute.split(':')[0], 10)
  const minute = parseInt(GMTOffsetHourMinute.split(':')[1], 10)

  const offset = hour * 3600 * 1000 + minute * 60 * 10000

  const dateTime = GMTOffset.includes('+')
    ? new Date(greenwichDate).getTime() + offset
    : new Date(greenwichDate).getTime() - offset

  if (typeof greenwichDate === 'number') {
    return format ? dayjs(dateTime).utc().format(format) : dayjs(dateTime).utc()
  } else {
    return format ? dayjs(dateTime).format(format) : dayjs(dateTime)
  }
}

/**
 * @description: 当前时区时间转GMT 对应的 当地时间戳
 * @param {*} hereDate  1659925678357 时间戳
 * @param {*} format
 * @param {*} GMTOffset 'GMT-09:00'
 * @return {*}
 */
export const formatDateHere2GMT = (hereDate, GMTOffset) => {
  if (typeof hereDate === 'string') {
    hereDate = hereDate.replace(/-/g, '/')
  }
  if (!dayjs(hereDate).isValid()) {
    return ''
  }

  const GMTOffsetHourMinute = GMTOffset.replace(/GMT/, '').replace(/-/, '').replace(/\+/, '')

  const hour = parseInt(GMTOffsetHourMinute.split(':')[0], 10)
  const minute = parseInt(GMTOffsetHourMinute.split(':')[1], 10)

  const offset = hour * 3600 * 1000 + minute * 60 * 10000

  const dateTime = GMTOffset.includes('-')
    ? dayjs(hereDate).utc(true).valueOf() + offset
    : dayjs(hereDate).utc(true).valueOf() - offset
  return dateTime
}

/**
 * @Desc 设置timePicker 默认显示
 * @param time
 * @returns {string}
 */
export const setDefaultValue = time => {
  const CurrentTime = dayjs().format('M/D/YYYY')
  return dayjs(`${CurrentTime} ${time}`).format('HH:mm')
}
