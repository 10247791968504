import classNames from 'clsx'
import { observer } from 'mobx-react'
import PropTypes from 'prop-types'

import { useState } from 'react'

import Collapse from '@material-ui/core/Collapse'
// @material-ui/core components
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import { makeStyles } from '@material-ui/core/styles'

// Custom Components
import { useStore } from '@Hooks/useStore'

import { setAccountNamespace } from '@Utils/formatLocationData'

// Custom components
import headerLinksStyle from '@Common/jss/components/headerLinksStyle'

const useStyles = makeStyles(headerLinksStyle)

const HeaderLinks = props => {
  const { handleLogOutClick, handleSalesRestoreClick } = props
  const {
    user: { userInfo }
  } = useStore()
  const classes = useStyles()
  const [openAvatar, setOpenAvatar] = useState(false)
  const openCollapse = () => {
    setOpenAvatar(!openAvatar)
  }
  const userName = userInfo ? setAccountNamespace(userInfo) : 'CA'
  // 点击退出登录触发事件
  const logOutHandle = () => {
    if (handleLogOutClick) {
      handleLogOutClick()
    }
  }

  const salseResoreHandle = () => {
    handleSalesRestoreClick && handleSalesRestoreClick()
  }
  return (
    <div className={classes.root}>
      <div className={classes.user}>
        <div
          className={`${classes.itemLink} ${classes.userCollapseButton}`}
          onClick={openCollapse}
        >
          <div className={classes.photo}>{userName}</div>
          <ListItemText
            className={classes.userItemText}
            disableTypography={true}
            primary={
              <span className={`${classes.primary} ${classes.userNameDisplay}`}>
                {userInfo.userName} {userInfo.nickname}
              </span>
            }
            secondary={
              <b className={classNames([classes.caret, classes.userCaret, openAvatar ? classes.caretActive : ''])} />
            }
          />
        </div>
        <Collapse
          className={classes.collapse}
          in={openAvatar}
          unmountOnExit
        >
          <List className={`${classes.list} ${classes.collapseList}`}>
            <ListItem className={classes.collapseItem}>
              <div
                className={`${classes.itemLink} ${classes.userCollapseLinks}`}
                onClick={logOutHandle}
              >
                <svg
                  aria-hidden="true"
                  className={classNames('icon', classes.buttonIcon)}
                >
                  <use xlinkHref="#icon-tuichu1" />
                </svg>
                <ListItemText
                  className={classes.collapseItemText}
                  disableTypography={true}
                  primary={'Sign out'}
                />
              </div>
            </ListItem>
            {userInfo.isDemoUser && (
              <ListItem className={classes.collapseItem}>
                <div
                  className={`${classes.itemLink} ${classes.userCollapseLinks}`}
                  onClick={salseResoreHandle}
                >
                  <svg
                    aria-hidden="true"
                    className={classNames('icon', classes.buttonIcon)}
                    style={{ transform: 'rotateY(180deg)' }}
                  >
                    <use xlinkHref="#icon-gengxin" />
                  </svg>
                  <ListItemText
                    className={classes.collapseItemText}
                    disableTypography={true}
                    primary={'Restart the Demo'}
                  />
                </div>
              </ListItem>
            )}
          </List>
        </Collapse>
      </div>
    </div>
  )
}

HeaderLinks.propTypes = {
  rtlActive: PropTypes.bool,
  handleLogOutClick: PropTypes.func,
  handleSalesRestoreClick: PropTypes.func
}

export default observer(HeaderLinks)
