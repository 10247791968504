import classNames from 'clsx'
import { observer } from 'mobx-react'
import PropTypes from 'prop-types'

import { Fragment, useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'

import { useMediaQuery } from '@material-ui/core'
import Dialog from '@material-ui/core/Dialog'
// @material-ui/core components
import { useTheme } from '@material-ui/core/styles'
import { makeStyles } from '@material-ui/core/styles'

import CustomFullScreenModal from '@Components/CustomFullScreenModal/CustomFullScreenModal'
import CustomTopbar from '@Components/Navbar/TopBar'
// Custom Components
import Sidebar from '@Components/Sidebar/Sidebar'

import useDtenDialog from '@Hooks/useDtenDialog'
import useLoadingApp from '@Hooks/useLoadingApp'
// Custom Hooks
import { useStore } from '@Hooks/useStore'

// import { PERMISSION_SHOW_ACCOUNTS } from 'src/Utils/constants'
// CSS Module
import adminLayoutStyle from '@Common/jss/layouts/adminLayoutStyle'

import HeaderDialogs from './HeaderDialogs'

const useStyles = makeStyles(adminLayoutStyle)

const AdminLayout = props => {
  const { children } = props
  const {
    user: {
      fetchLogOut,
      fetchLoadUserInfo,
      userInfo,
      fetchSalesDemoRestore,
      fetchSalesDemoResetAgreement,
      fetchPermissions
    },
    route: { routeLists, fetchSidebarRoutes },
    devices: { setFiltrateFieldsEmpty },
    mAgreement: { setQueryParamsInit },
    base: { menusShow, fetchMenuShow }
  } = useStore()
  const history = useHistory()
  // 设备列表页和详情页以外的页面清除查询缓存

  const { setLoadingInit } = useLoadingApp()

  const clearStore = () => {
    if (
      history.location.pathname.indexOf('/device/manage') !== 0 &&
      history.location.pathname.indexOf('/detail/') !== 0
    ) {
      setFiltrateFieldsEmpty()
    }
    if (!history.location.pathname.includes('/service/agreement')) {
      setQueryParamsInit()
    }
  }

  useEffect(() => {
    clearStore()
  }, [window.location.pathname])

  const { dialogType, setDialogType, dialogInfo, setDialogInfo, open, showDialog, closeDialog } = useDtenDialog()

  const [isInitialized, setIsInitialized] = useState(false)

  const [miniActive, setMiniActive] = useState(false)
  const classes = useStyles()

  const alertUserGuide = () => {
    setDialogInfo({
      key: 'User Guide',
      uniqueId: 'User_Guide',
      cancel: true
    })
    setDialogType('userGuideWarn')
    showDialog()
  }

  // const alertUserGuideChoose = () => {
  //   setDialogInfo({
  //     key: 'User Guide',
  //     uniqueId: 'User_Guide_Choose_Merge',
  //     cancel: true
  //   })
  //   setDialogType('userGuideChooseMerge')
  //   showDialog()
  // }

  // const alertUserGuideChooseNext = () => {
  //   setDialogInfo({
  //     key: 'User Guide',
  //     uniqueId: 'User_Guide_Choose_Merge_Next',
  //     cancel: true
  //   })
  //   setDialogType('userGuideChooseMergeNext')
  //   showDialog()
  // }

  // const alertUserGuideMergeSuccess = () => {
  //   setDialogInfo({
  //     key: 'User Guide',
  //     uniqueId: 'User_Guide_Merge_Success',
  //     cancel: true
  //   })
  //   setDialogType('userGuideMergeSuccess')
  //   showDialog()
  // }

  const userGuideChoose = {
    onConfirm: () => {
      console.log('userGuideChoose confirm')
      closeDialog()
    },
    onBack: () => {
      console.log('userGuideChoose back')
      closeDialog()
      alertUserGuide()
    }
  }

  useEffect(() => {
    // alertUserGuide()
    // alertUserGuideChoose()
    // alertUserGuideChooseNext()
    // alertUserGuideMergeSuccess()
  }, [])

  // 点击logout 触发弹框
  const handleLogOutClick = () => {
    if (!open) {
      setDialogType('logout')
      showDialog()
    }
  }
  // 点击销售 restore 触发弹框
  const handleSalesRestoreClick = () => {
    if (!open) {
      setDialogType('salesRestore')
      showDialog()
    }
  }
  // 点击销售 reset agreement 触发弹框
  const handleSalesResetAgreementClick = () => {
    if (!open) {
      setDialogType('salesReset')
      showDialog()
    }
  }

  // 点击关闭弹框
  const closeDialogHandle = () => {
    if (open) closeDialog()
  }
  // 关闭模态框并退出登录
  const logoutHandle = () => {
    closeDialog()
  }
  // 点击模态框确认触发logout事件
  const logoutConfirmHandle = () => {
    fetchLogOut(logoutHandle)
  }
  // 点击模态框确认触发销售demo restore事件
  const salesDemoRestoreConfirmHandle = () => {
    fetchSalesDemoRestore(logoutHandle)
  }

  // 点击模态框确认触发销售demo restore事件
  const salesDemoResetConfirmHandle = () => {
    fetchSalesDemoResetAgreement(logoutHandle)
  }

  const loadAll = async () => {
    setLoadingInit(true)
    await fetchMenuShow()
    fetchSidebarRoutes()
    await fetchLoadUserInfo()
    await fetchPermissions()
    setIsInitialized(true)
    setLoadingInit(false)
  }

  // effect instead of componentDidMount, componentDidUpdate and componentWillUnmount
  useEffect(() => {
    loadAll()
  }, [])

  const theme = useTheme()
  const isDesktop = useMediaQuery(theme.breakpoints.up('lg'), {
    defaultMatches: true
  })

  // const [openSidebar, setOpenSidebar] = useState(false)
  const [mobileOpen, setMobileOpen] = useState(false)

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen)
  }

  // const handleSidebarOpen = () => {
  //   setOpenSidebar(true)
  // }

  const handleSidebarClose = () => {
    handleDrawerToggle()
  }
  const sidebarMinimize = () => {
    setMiniActive(!miniActive)
  }

  // const shouldOpenSidebar = isDesktop ? true : openSidebar
  const mainPanelClasses = classNames({
    [classes.mainPanel]: true,
    [classes.mainPanelSidebarMini]: miniActive,
    [classes.mainPanelWithPerfectScrollbar]: navigator.platform.indexOf('Win') > -1
  })
  const configTopbar = {
    ...props,
    onSidebarOpen: handleDrawerToggle,
    sidebarMinimize: sidebarMinimize,
    miniActive: miniActive,
    onLogOutClick: handleLogOutClick,
    onSalesRestoreClick: handleSalesRestoreClick,
    onSalesResetAgreementClick: handleSalesResetAgreementClick
  }
  const showFullScreenModal = () => {
    // const { accountNumber, emailAddress } = userInfo
    // return !!(emailAddress && !accountNumber)
    return false
  }

  /* 
	递归处理菜单树
	*/
  const treeRecursion = dataRoutes => {
    function tree(routes) {
      let arr = []
      routes
        .filter(item => {
          if (item?.url === '/device/manage-authorization') {
            if (menusShow.permission) {
              return item
            }
          } else if (item?.url === '/device/update') {
            if (menusShow.patch) {
              return item
            }
          } else {
            return item
          }
          // if (PERMISSION_SHOW_ACCOUNTS.includes(userInfo?.accountNumber)) {
          //   return item
          // } else {
          //   return item?.url !== '/device/manage-authorization'
          // }
        })
        .filter(item => {
          // if (userInfo.hasD7Device) {
          // 	return item
          // } else {
          // 	return item?.url !== '/device/update'
          // }
          if (item?.url === '/device/update') {
            return userInfo.hasD7Device ? item : false
          } else {
            return item
          }
        })
        .forEach(item => {
          arr.push({
            ...item,
            routes: tree(item.routes)
          })
        })
      return arr
    }
    return tree(dataRoutes)
  }

  let routeListsLayout = treeRecursion(routeLists)

  const displayLayoutDialogs = {
    logout: (
      <HeaderDialogs
        closeHandle={closeDialogHandle}
        confirmHandle={logoutConfirmHandle}
        dialogType={dialogType}
        open={open}
      />
    ),
    salesRestore: (
      <HeaderDialogs
        closeHandle={closeDialogHandle}
        confirmHandle={salesDemoRestoreConfirmHandle}
        dialogType={dialogType}
        open={open}
      />
    ),
    salesReset: (
      <HeaderDialogs
        closeHandle={closeDialogHandle}
        confirmHandle={salesDemoResetConfirmHandle}
        dialogType={dialogType}
        open={open}
      />
    ),
    userGuideWarn: (
      <HeaderDialogs
        closeHandle={() => {}}
        confirmHandle={() => {}}
        dialogInfo={dialogInfo}
        dialogType={dialogType}
        gaTitle={'User_Guide_Warn'}
        open={open}
        title={`UserGuide`}
      />
    ),
    userGuideChooseMerge: (
      <HeaderDialogs
        closeHandle={userGuideChoose.onBack}
        confirmHandle={userGuideChoose.onConfirm}
        dialogInfo={dialogInfo}
        dialogType={dialogType}
        gaTitle={'User_Guide_Warn_Choose'}
        open={open}
        title={`UserGuide`}
      />
    ),
    userGuideMergeSuccess: (
      <HeaderDialogs
        closeHandle={userGuideChoose.onBack}
        confirmHandle={userGuideChoose.onConfirm}
        dialogInfo={dialogInfo}
        dialogType={dialogType}
        gaTitle={'User_Guide_Warn_Choose'}
        open={open}
        title={`UserGuide`}
      />
    ),
    userGuideChooseMergeNext: (
      <HeaderDialogs
        closeHandle={userGuideChoose.onBack}
        confirmHandle={userGuideChoose.onConfirm}
        dialogInfo={dialogInfo}
        dialogType={dialogType}
        gaTitle={'User_Guide_Warn_Choose'}
        open={open}
        title={`UserGuide`}
      />
    )
  }

  const DisplayDialog = () => {
    if (!Object.keys(displayLayoutDialogs).includes(dialogType)) {
      console.log('Please check displayLayoutDialogs', dialogType)
      return null
    }
    return displayLayoutDialogs[dialogType]
  }

  return (
    <div
      className={classNames({
        [classes.root]: true,
        [classes.shiftContent]: !showFullScreenModal() && isDesktop
      })}
    >
      <CustomTopbar
        {...configTopbar}
        hiddenSmMenu={false}
        userInfo={userInfo}
      />
      {showFullScreenModal() ? (
        <CustomFullScreenModal />
      ) : (
        <Fragment>
          <Sidebar
            handleLogOutClick={handleLogOutClick}
            handleSalesResetAgreementClick={handleSalesResetAgreementClick}
            handleSalesRestoreClick={handleSalesRestoreClick}
            open={mobileOpen}
            pages={routeListsLayout}
            onClose={handleSidebarClose}
          />
          <main className={mainPanelClasses}>
            <div className={classes.innerContainer}>
              <div className={classes.content}>{isInitialized ? children : null}</div>
            </div>
          </main>
        </Fragment>
      )}
      <Dialog
        aria-describedby="doubleCheck-description"
        aria-labelledby="doubleCheck-title"
        maxWidth={'md'}
        open={open}
      >
        {<DisplayDialog />}
      </Dialog>
    </div>
  )
}

AdminLayout.propTypes = {
  children: PropTypes.node
}

export default observer(AdminLayout)
