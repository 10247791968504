import classesNames from 'clsx'
import PropTypes from 'prop-types'

import Link from '@material-ui/core/Link'
import { makeStyles } from '@material-ui/core/styles'

// Css In Js
import footerStyle from '@Common/jss/components/footerStyle'

import { LINK_PRIVACY_POLICY_COOKIE, LINK_TERMS_OF_USE } from 'src/Utils/constants'

// Custom Components
import Typography from '../Typography/Typography'

const useStyles = makeStyles(footerStyle)

function Copyright() {
  return (
    <Typography
      color="textSecondary"
      variant="body2"
    >
      {`Copyright © ${new Date().getFullYear()} DTEN, Inc. All rights reserved.`}
    </Typography>
  )
}

const Footer = props => {
  const { border = true } = props
  const classes = useStyles()
  return (
    <footer
      className={classesNames({
        [classes.footer]: true,
        [classes.border]: border
      })}
    >
      <div className={classes.footerContent}>
        <div className={classes.linkWrapper}>
          <Link
            color="inherit"
            href={LINK_PRIVACY_POLICY_COOKIE}
            rel="noopener"
            target="_blank"
            underline="none"
          >
            Privacy Policy
          </Link>
          <span className={classes.line} />
          <Link
            color="inherit"
            href={LINK_TERMS_OF_USE}
            rel="noopener"
            target="_blank"
            underline="none"
          >
            Terms of Use
          </Link>
        </div>
        <Copyright />
      </div>
    </footer>
  )
}

Footer.propTypes = {
  border: PropTypes.bool
}

export default Footer
