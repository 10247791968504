import { styled, Typography } from '@material-ui/core'

import { fontRegular } from '@Common/jss/'

const ContentTypographyHead = styled(Typography)(() => ({
  color: '#2C2C2C',
  fontSize: '14px',
  ...fontRegular,
  lineHeight: '21px',
  fontWeight: 400
}))

const GreenEmailTypography = styled(Typography)(() => ({
  color: '#4EB857',
  fontSize: '14px',
  ...fontRegular,
  lineHeight: '21px',
  fontWeight: 400
}))

const BoxText = () => {
  return (
    <>
      <ContentTypographyHead component="div">
        {`You confirm to select the `}
        <GreenEmailTypography component="span">leiwang@dten.com.cn</GreenEmailTypography>{' '}
        {` account as the Owner account after the organization account merge, if other users also select this account, the system will perform the organization account merge. Do you confirm this selection?`}
      </ContentTypographyHead>
    </>
  )
}

export default BoxText
