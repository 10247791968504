import { Base64 } from 'js-base64'
import { cloneDeep } from 'lodash'
import { action, flow, makeObservable, observable, runInAction } from 'mobx'

import { removeLocalInfo } from 'src/Utils/cache'

import { getSidebarLists } from '@Service/api'

class RouteStore {
  constructor(rootStore) {
    makeObservable(this, {
      fetchSidebarState: observable,
      routeLists: observable,

      queryParamsGroup: observable,
      setQueryParamsGroup: action.bound,
      getQueryParamsGroup: action.bound,

      queryParams: observable,
      setQueryParams: action.bound,
      fetchSidebarRoutes: action.bound
    })

    this.rootStore = rootStore
  }
  fetchSidebarState = 'pending'
  routeLists = []
  queryParams = localStorage.getItem('queryParams') ? localStorage.getItem('queryParams') : ''

  setQueryParams(values) {
    runInAction(() => {
      this.queryParams = Base64.encode(JSON.stringify(values))
      localStorage.setItem('queryParams', Base64.encode(JSON.stringify(values)))
    })
  }

  queryParamsGroup = localStorage.getItem('queryParamsGroup') ? localStorage.getItem('queryParamsGroup') : ''

  setQueryParamsGroup(values) {
    runInAction(() => {
      this.queryParamsGroup = Base64.encode(JSON.stringify(values))
      localStorage.setItem('queryParamsGroup', Base64.encode(JSON.stringify(values)))
    })
  }

  getQueryParamsGroup() {
    const obj = this.queryParamsGroup
    console.log('obj,,,', obj)
    return obj ? JSON.parse(Base64.decode(obj)) : {}
  }

  fetchSidebarRoutes = flow(function* () {
    const self = this
    self.fetchSidebarState = 'pending'
    try {
      const response = yield getSidebarLists()
      if (response.data.success) {
        const { resource } = response.data.data

        if (resource) {
          self.routeLists = cloneDeep(resource)
          self.fetchSidebarState = 'done'
        } else {
          removeLocalInfo()
          window.location.href = '/registration/login'
        }
      }
    } catch (e) {
      self.fetchDevicesState = 'error'
    }
  })
}

export default RouteStore
