import classNames from 'clsx'
import { Base64 } from 'js-base64'
import PropTypes from 'prop-types'

import Chip from '@material-ui/core/Chip'
import ListItem from '@material-ui/core/ListItem'
import { makeStyles } from '@material-ui/core/styles'

import { useStore } from '@Hooks/useStore'

import { successColor } from '@Common/jss'

import { getLocationQuery } from 'src/Utils'

const useStyles = makeStyles(() => ({
  link: {
    display: 'flex',
    paddingLeft: 60,
    paddingTop: 8,
    paddingBottom: 8,
    color: '#6D6E71',
    '& :hover': {
      color: '#6D6E71 !important'
    }
  },
  name: {
    fontSize: '14px',
    fontFamily: '"Lato Regular", "Lato-Regular", "Lato"',
    fontWeight: 400,
    lineHeight: '18px'
  },
  active: {
    '& $name': {
      color: successColor[0],
      fontFamily: '"Lato Bold", "Lato-Bold", "Lato", sans-serif',
      fontWeight: 'bold'
    }
  }
}))

/**
 * @description: 子菜单高亮逻辑
 * @param {*} props
 * @return {*}
 */

const SidebarLink = props => {
  const { name, to, badge, component } = props
  const {
    route: { queryParams }
  } = useStore()

  const groupid = getLocationQuery(`groupid`)

  const classes = useStyles()
  const activeRoute = routeName => {
    const { from } = queryParams ? JSON.parse(Base64.decode(queryParams)) : { form: '' }
    const locationHref = window.location.href

    if (groupid) {
      if (routeName === '/device/group') {
        return 'active'
      }
      return ''
    } else {
      if (routeName === '/device/manage' && locationHref.includes('detail') && from === 'manage') {
        return 'active'
      }
      if (routeName === '/device/manage') {
        return locationHref.indexOf(routeName) > -1 && locationHref.indexOf('/device/manage-authorization') === -1
          ? 'active'
          : ''
      }
    }

    if (routeName === '/members/users' && locationHref.includes('/pending')) {
      return 'active'
    }

    if (routeName === '/service/partner/profile' && window.location.pathname === '/service/partner/permission') {
      return 'active'
    }
    return locationHref.indexOf(routeName) > -1 ? 'active' : ''
  }

  const navLinkItemClasses = classNames({
    [classes.link]: true,
    [classes.active]: activeRoute(to)
  })
  return (
    <ListItem
      className={navLinkItemClasses}
      component={component}
      to={to}
      button
      dense
      exact
    >
      <span className={classes.name}>{name}</span>
      {badge ? <Chip label={badge} /> : ''}
    </ListItem>
  )
}

SidebarLink.propTypes = {
  name: PropTypes.string,
  to: PropTypes.string,
  badge: PropTypes.node,
  component: PropTypes.elementType
}

export default SidebarLink
