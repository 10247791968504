import { observer } from 'mobx-react'

import { lazy, Suspense } from 'react'
import { Redirect, Route, BrowserRouter as Routers, Switch } from 'react-router-dom'

import Loading from '@Components/Loading/Loading'
import RouteWithLayout from '@Components/RouteWithLayout/RouteWithLayout'

import useGoogleAnalytics from '@Hooks/UseGoogleAnalytics'

import ActivateLayout from '@Layouts/ActivateLayout'
import AdminLayout from '@Layouts/AdminLayout'
import FeedbackLayout from '@Layouts/FeedbackLayout'
import HelpLayout from '@Layouts/HelpLayout'
import LogLayout from '@Layouts/LogAdminLayout'
import PermissionLayout from '@Layouts/PermissionLayout'
import ScanLayout from '@Layouts/ScanLayout'
import ThirdPartyAuthLayout from '@Layouts/ThirdPartyAuthLayout'

import Activation from '@Views/Activation'
import Admin from '@Views/Admin'
import Exception404 from '@Views/Exception/Exception404'
import ExternalLinks from '@Views/ExternalLinks'
import Scan from '@Views/Scan'
import ThirdPartyAuth from '@Views/ThirdPartyAuth'

import AutoLayout from '@/Layouts/AutoLayout'

const Registration = lazy(() => import(`@/Views/Auth`))
const Help = lazy(() => import(`@Views/Help`))
const Feedback = lazy(() => import(`@Views/Feedback`))
const Log = lazy(() => import(`@Views/Log`))
const Agreement = lazy(() => import(`@Views/Agreement`))
const Explain = lazy(() => import(`@Views/Explain`))

function Routes() {
  process.env.NODE_ENV === 'production' && useGoogleAnalytics()
  let urlParams = '/registration/login'
  // 防止参数丢失
  if (window.location.href.includes('?')) {
    urlParams = `/registration/login${window.location.search}`
  }
  return (
    <Suspense fallback={<Loading />}>
      <Switch>
        <Redirect
          from="/"
          to={urlParams}
          exact
        />
        <RouteWithLayout
          component={Registration}
          layout={AutoLayout}
          path="/registration"
        />
        <RouteWithLayout
          component={Activation}
          layout={ActivateLayout}
          path="/activation"
        />
        <RouteWithLayout
          component={ThirdPartyAuth}
          layout={ThirdPartyAuthLayout}
          path="/approve"
        />
        <RouteWithLayout
          component={ExternalLinks}
          layout={PermissionLayout}
          path="/links"
        />
        <RouteWithLayout
          component={Explain}
          layout={LogLayout}
          path="/explain"
        />
        <RouteWithLayout
          component={Feedback}
          layout={FeedbackLayout}
          path="/feedback"
        />
        <RouteWithLayout
          component={Log}
          layout={LogLayout}
          path="/log"
        />
        <RouteWithLayout
          component={Agreement}
          layout={LogLayout}
          path="/agreement"
        />
        <RouteWithLayout
          component={Scan}
          layout={ScanLayout}
          path="/scan"
        />
        <RouteWithLayout
          component={Help}
          layout={HelpLayout}
          path="/help"
        />
        <RouteWithLayout
          component={Admin}
          layout={AdminLayout}
          path="/"
        />
        <Route render={props => <Exception404 {...props} />} />
      </Switch>
    </Suspense>
  )
}

const Router = () => {
  return (
    <Routers keyLength={12}>
      <Routes />
    </Routers>
  )
}

export default observer(Router)
