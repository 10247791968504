import classNames from 'clsx'

// import { HelpOutline } from '@material-ui/icons'
import { makeStyles } from '@material-ui/core'
import Link from '@material-ui/core/Link'

import sidebarFooterStyle from '@Common/jss/components/sidebarFooterStyle'

// import CustomTooltip from '../Tooltip/Tooltip'
import { SUPPORT_EMAIL, SUPPORT_PHONE } from 'src/Utils/constants'
import { LINK_PRIVACY_POLICY_COOKIE, LINK_TERMS_OF_USE } from 'src/Utils/constants'

import GridContainer from '../Grid/GridContainer'
import GridItem from '../Grid/GridItem'

const userStyle = makeStyles(sidebarFooterStyle)

const SidebarFooter = () => {
  const classes = userStyle()

  const CustomSupport = () => {
    return (
      <ul className={classes.support}>
        <li className={classes.supportcontent}>
          <svg
            aria-hidden="true"
            className={classNames('icon', classes.supportIcon)}
          >
            <use xlinkHref="#icon-callColor" />
          </svg>
          {SUPPORT_PHONE}
        </li>
        <li className={classes.supportcontent}>
          <svg
            aria-hidden="true"
            className={classNames('icon', classes.supportIcon)}
          >
            <use xlinkHref="#icon-mailColor" />
          </svg>
          {SUPPORT_EMAIL.toUpperCase()}
        </li>
      </ul>
    )
  }

  return (
    <GridContainer spacing={1}>
      <GridItem xs={12}>
        <CustomSupport />
      </GridItem>
      <GridItem
        className={classes.copyright}
        xs={9}
      >
        {`Copyright © ${new Date().getFullYear()} DTEN, Inc.`}
      </GridItem>
      <GridItem
        className={classes.linkContent}
        xs={6}
      >
        <Link
          className={classes.link}
          color="inherit"
          href={LINK_PRIVACY_POLICY_COOKIE}
          rel="noopener"
          target="_blank"
          underline="none"
        >
          Privacy Policy
        </Link>
      </GridItem>
      <GridItem
        className={classes.linkContent}
        xs={6}
      >
        <Link
          className={classes.link}
          color="inherit"
          href={LINK_TERMS_OF_USE}
          rel="noopener"
          target="_blank"
          underline="none"
        >
          Terms of Use
        </Link>
      </GridItem>
    </GridContainer>
  )
}

export default SidebarFooter
