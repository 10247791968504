import PropTypes from 'prop-types'

import { Fragment, lazy, Suspense, useMemo } from 'react'
import { Link, Route, Switch } from 'react-router-dom'

// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles'
import NavigateNextIcon from '@material-ui/icons/NavigateNext'

import GridContainer from '@Components/Grid/GridContainer'
import GridItem from '@Components/Grid/GridItem'
// Custom Components
import Loading from '@Components/Loading/Loading'
import Typography from '@Components/Typography/Typography'

// Custom Styles
import pageStyles from '@Common/jss/pageStyles'

const useStyles = makeStyles(pageStyles)

// Custom Views
const SingleSignOn = lazy(() => import('./SingleSignOn/SingleSignOn'))
const Cubicle = lazy(() => import('./DtenCubicle/Cubicle'))
const AppCollection = lazy(() => import('./AppCollection/AppCollection'))

const INIT_PATH_INFO = {
  collection: '',
  cubicle: 'DTEN Cubicle',
  'sign-on': 'Single Sign-On(SSO)'
}
const Applications = props => {
  const { location } = props
  const classes = useStyles()
  function handleClick(event) {
    event.preventDefault()
    console.info('You clicked a breadcrumb.')
  }

  const currentPath = useMemo(() => {
    const { pathname } = location
    const current = pathname && pathname.split('/').length > 2 ? pathname.split('/')[2] : ''
    return current ? INIT_PATH_INFO[current] : ''
  }, [location])
  return (
    <GridContainer className={classes.root}>
      <GridItem sm={12}>
        <div className={classes.pageTitle}>
          <Link
            className={classes.home}
            to={'/applications'}
          >
            Security
          </Link>
          {currentPath && (
            <Fragment>
              <NavigateNextIcon />
              <Typography
                className={classes.link}
                onClick={handleClick}
              >
                {currentPath}
              </Typography>
            </Fragment>
          )}
        </div>
      </GridItem>
      <GridItem sm={12}>
        <Suspense fallback={<Loading />}>
          <Switch>
            {/*<Redirect*/}
            {/*  from="/applications"*/}
            {/*  to="/applications/collection"*/}
            {/*  exact*/}
            {/*/>*/}

            <Route
              path={'/applications/collection'}
              render={props => <AppCollection {...props} />}
            />
            {/*<Route*/}
            {/*  path={'/applications'}*/}
            {/*  render={props => <AppCollection {...props} />}*/}
            {/*/>*/}
            {/*<Route*/}
            {/*  path={'/applications*'}*/}
            {/*  render={props => <AppCollection {...props} />}*/}
            {/*/>*/}
            <Route
              path={'/applications'}
              render={props => <AppCollection {...props} />}
              exact
            />

            <Route
              path={'/applications/sign-on'}
              render={props => <SingleSignOn {...props} />}
            />
            <Route
              path={'/applications/cubicle'}
              render={props => <Cubicle {...props} />}
            />
          </Switch>
        </Suspense>
      </GridItem>
    </GridContainer>
  )
}

Applications.propTypes = {
  location: PropTypes.object
}

export default Applications
