// import 'mobx-react-lite/batchingForReactDom'
// import { StrictMode } from 'react'
import * as Sentry from '@sentry/react'

import ReactDOM from 'react-dom/client'

import { StoreProvider } from 'src/Store'

import App from './App'
import './Common/fonts/iconfont'
import './Common/styles/index.scss'
import { AppProvider } from './Context/AppContext'
import { showVersion } from './config'
import * as serviceWorker from './serviceWorker'

import 'src/Common/styles/antd.css'

Sentry.init({
  dsn: 'https://df98a6a9c9074787af5d16dadc8d43f0@sentry.dten.dev/6',
  integrations: [new Sentry.BrowserTracing()],
  tracesSampleRate: 1.0
})

showVersion()

const root = ReactDOM.createRoot(document.getElementById('root'))

root.render(
  <StoreProvider>
    <AppProvider>
      <App />
    </AppProvider>
  </StoreProvider>
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
