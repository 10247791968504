/**
 * @desc: DateRange Component
 * @author: OurTime...
 * @date: 2021/1/8
 */
// eslint-disable-next-line no-unused-vars
import classNames from 'clsx'
import PropTypes from 'prop-types'

import { useState } from 'react'
import * as React from 'react'

import ClickAwayListener from '@material-ui/core/ClickAwayListener'
// @material-ui/core components
import Grow from '@material-ui/core/Grow'
import Paper from '@material-ui/core/Paper'
import Popper from '@material-ui/core/Popper'
import { withStyles } from '@material-ui/core/styles'

// Css In Js
import dataRangeStyle from '@Common/jss/components/dateRangeStyle'

// Custom Components
import Button from '../CustomButtons/Button'
import { WrapperVariantContext } from '../Picker/Context/WrapperVariantContext'
// Custom Hooks
import { useCalendarState } from '../Picker/Hooks/useCalendarState'
import { usePickerState } from '../Picker/Hooks/usePickerState'
import { useUtils } from '../Picker/Hooks/useUtils'
// Custom Utils
import StaticDateRangePicker from '../Picker/StaticDateRangePicker'
import { calculateRangeChange } from '../Picker/Utils/date-range-manager'
import { isRangeValid, parseRangeInputValue } from '../Picker/Utils/date-utils'

// import { defaultMaxDate, defaultMinDate } from '../Picker/Utils'
// import { useParsedDate } from '../Picker/Hooks/date-helpers-hooks'

const rangePickerValueManager = {
  emptyValue: [null, null],
  parseInput: parseRangeInputValue,
  areValuesEqual: (utils, a, b) => utils.isEqual(a[0], b[0]) && utils.isEqual(a[1], b[1])
}

let currentDate = {}

const DateRange = props => {
  const {
    classes,
    calendars = 2,
    buttonProps,
    buttonIcon,
    buttonText = 'Date range',
    appendText = '',
    caret = true,
    dropup,
    rtlActive,
    dropPlacement,
    navDropdown,
    date,
    onMonthChange,
    onChange,
    onConfirm,
    defaultCalendarMonth,
    disableFuture = false,
    // maxDate: __maxDate = defaultMinDate,
    // minDate: __minDate = defaultMaxDate,
    maxDate,
    minDate,
    disablePast = false,
    reduceAnimations,
    shouldDisableDate,
    disableHighlightToday,
    disableAutoMonthSwitching = false,
    dateFormat: passedDateFormat,
    noShadow = false,
    ...other
  } = props

  const utils = useUtils()
  // console.log(utils)
  // const minDate = useParsedDate(__minDate)
  // const maxDate = useParsedDate(__maxDate)

  const [anchorEl, setAnchorEl] = useState(null)
  const [currentlySelectingRangeEnd, setCurrentlySelectingRangeEnd] = React.useState('start')
  const pickerStateProps = {
    ...other,
    date,
    onChange,
    inputFormat: passedDateFormat || utils.formats.keyboardDate
  }
  const {
    pickerProps: { onDateChange }
  } = usePickerState(pickerStateProps, rangePickerValueManager)
  const wrapperVariant = React.useContext(WrapperVariantContext)
  // clicked Date Range buttons trigger event
  const handleClick = event => {
    currentDate = date
    if (anchorEl && anchorEl.contains(event.target)) {
      setAnchorEl(null)
    } else {
      setAnchorEl(event.currentTarget)
    }
  }
  const [start, end] = date

  const { changeMonth, calendarState, isDateDisabled, onMonthSwitchingAnimationEnd, changeFocusedDay } =
    useCalendarState({
      date: start || end,
      defaultCalendarMonth,
      disableFuture,
      disablePast,
      disableSwitchToMonthOnDayFocus: true,
      maxDate,
      minDate,
      onMonthChange,
      reduceAnimations,
      shouldDisableDate
    })

  const scrollToDayIfNeeded = day => {
    if (!day || !utils.isValid(day) || isDateDisabled(day)) {
      return
    }

    const currentlySelectedDate = currentlySelectingRangeEnd === 'start' ? start : end
    if (currentlySelectedDate === null) {
      // do not scroll if one of ages is not selected
      return
    }

    const displayingMonthRange = wrapperVariant === 'mobile' ? 0 : calendars - 1
    const currentMonthNumber = utils.getMonth(calendarState.currentMonth)
    const requestedMonthNumber = utils.getMonth(day)

    if (
      !utils.isSameYear(calendarState.currentMonth, day) ||
      requestedMonthNumber < currentMonthNumber ||
      requestedMonthNumber > currentMonthNumber + displayingMonthRange
    ) {
      const newMonth =
        currentlySelectingRangeEnd === 'start'
          ? currentlySelectedDate
          : // If you need to focus end, scroll to the state when "end" is displaying in the last calendar
            utils.addMonths(currentlySelectedDate, -displayingMonthRange)

      changeMonth(newMonth)
    }
  }

  React.useEffect(() => {
    if (disableAutoMonthSwitching || !open) {
      return
    }
    scrollToDayIfNeeded(currentlySelectingRangeEnd === 'start' ? start : end)
  }, [currentlySelectingRangeEnd, date])

  const handleChange = React.useCallback(
    newDate => {
      const { nextSelection, newRange } = calculateRangeChange({
        newDate,
        utils,
        range: date,
        currentlySelectingRangeEnd
      })
      setCurrentlySelectingRangeEnd(nextSelection)
      const isFullRangeSelected = currentlySelectingRangeEnd === 'end' && isRangeValid(utils, newRange)
      onDateChange(newRange, wrapperVariant, isFullRangeSelected ? 'finish' : 'partial')
    },
    [currentlySelectingRangeEnd, date, onDateChange, setCurrentlySelectingRangeEnd, utils, wrapperVariant]
  )

  const handleClickAway = () => {
    setAnchorEl(null)
  }

  /**
   * @Desc Clicked the cancel button triggers the event
   * @param event
   */
  const cancelHandle = event => {
    setAnchorEl(null)
    if (onChange) onChange(currentDate)
    if (anchorEl.contains(event.target)) {
      return
    }
  }

  const confirmHandle = values => {
    setAnchorEl(null)
    if (onConfirm) onConfirm(values)
  }

  const quickRangeChange = values => {
    if (values && onChange) {
      onChange(values)
      // if (onConfirm) onConfirm(values)
    }
  }
  const caretClasses = classNames({
    [classes.caret]: true,
    [classes.caretDropup]: dropup && !anchorEl,
    [classes.caretActive]: Boolean(anchorEl) && !dropup,
    [classes.caretRTL]: rtlActive
  })

  const renderView = () => {
    const sharedCalendarProps = {
      date,
      isDateDisabled,
      changeFocusedDay,
      onChange: handleChange,
      reduceAnimations,
      disableHighlightToday,
      onMonthSwitchingAnimationEnd,
      changeMonth,
      currentlySelectingRangeEnd,
      disableFuture,
      disablePast,
      minDate,
      maxDate,
      ...calendarState,
      ...other
    }
    return (
      <StaticDateRangePicker
        calendars={calendars}
        cancelHandle={cancelHandle}
        confirmHandle={confirmHandle}
        quickRangeChange={quickRangeChange}
        {...sharedCalendarProps}
      />
    )
  }

  return (
    <ClickAwayListener onClickAway={handleClickAway}>
      <div className={classes.root}>
        <div className={buttonText !== undefined ? '' : classes.target}>
          <Button
            aria-haspopup="true"
            aria-label="Notifications"
            aria-owns={anchorEl ? 'menu-list' : null}
            className={classNames({
              [classes.btnDefault]: true,
              [classes.noShadow]: noShadow
            })}
            {...buttonProps}
            onClick={handleClick}
          >
            {buttonIcon !== undefined ? <props.buttonIcon className={classes.buttonIcon} /> : null}
            {buttonText !== undefined ? buttonText : null}
            {appendText}
            {caret ? <b className={caretClasses} /> : null}
          </Button>
        </div>
        <Popper
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          placement={dropPlacement}
          className={classNames({
            [classes.popperClose]: !anchorEl,
            [classes.pooperResponsive]: true,
            [classes.pooperNav]: Boolean(anchorEl) && navDropdown
          })}
        >
          {() => (
            <Grow
              id="menu-list"
              in={Boolean(anchorEl)}
              style={dropup ? { transformOrigin: '0 100% 0' } : { transformOrigin: '0 0 0' }}
            >
              <Paper className={classes.dropdown}>{renderView()}</Paper>
            </Grow>
          )}
        </Popper>
      </div>
    </ClickAwayListener>
  )
}

DateRange.propTypes = {
  calendars: PropTypes.oneOf([1, 2, 3]),
  disableAutoMonthSwitching: PropTypes.bool,
  maxDate: PropTypes.any,
  minDate: PropTypes.any,
  date: PropTypes.arrayOf(
    PropTypes.oneOfType([PropTypes.any, PropTypes.instanceOf(Date), PropTypes.number, PropTypes.string])
  ).isRequired,
  classes: PropTypes.object,
  buttonProps: PropTypes.object,
  buttonIcon: PropTypes.node,
  buttonText: PropTypes.string,
  appendText: PropTypes.string,
  caret: PropTypes.bool,
  dropup: PropTypes.any,
  rtlActive: PropTypes.bool,
  dropPlacement: PropTypes.node,
  navDropdown: PropTypes.node,
  onMonthChange: PropTypes.func,
  onChange: PropTypes.func,
  onConfirm: PropTypes.func,
  defaultCalendarMonth: PropTypes.any,
  disableFuture: PropTypes.bool,

  disablePast: PropTypes.bool,
  reduceAnimations: PropTypes.any,
  shouldDisableDate: PropTypes.bool,
  disableHighlightToday: PropTypes.bool,
  dateFormat: PropTypes.string,
  noShadow: PropTypes.bool
}

export default withStyles(dataRangeStyle, { name: 'CustomDateRange' })(DateRange)
