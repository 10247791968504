import { useCallback, useEffect, useLayoutEffect, useRef } from 'react'

export const useIsomorphicEffect = typeof window === 'undefined' ? useEffect : useLayoutEffect

export function runKeyHandler(event, keyHandlers) {
  const handler = keyHandlers[event.keyCode]
  if (handler) {
    handler()
    event.preventDefault()
  }
}

export function useKeyDownHandler(active, keyHandlers) {
  const keyHandlersRef = useRef(keyHandlers)
  keyHandlersRef.current = keyHandlers

  return useCallback(
    event => {
      if (active) {
        runKeyHandler(event, keyHandlersRef.current)
      }
    },
    [active]
  )
}

export function useGlobalKeyDown(active, keyHandlers) {
  const keyHandlersRef = useRef(keyHandlers)
  keyHandlersRef.current = keyHandlers

  useIsomorphicEffect(() => {
    if (active) {
      const handleKeyDown = event => {
        runKeyHandler(event, keyHandlersRef.current)
      }
      document.addEventListener('keydown', handleKeyDown)
      return () => {
        document.removeEventListener('keydown', handleKeyDown)
      }
    }

    return undefined
  }, [active])
}

export const keycode = {
  ArrowUp: 38,
  ArrowDown: 40,
  ArrowLeft: 37,
  ArrowRight: 39,
  Enter: 13,
  Home: 36,
  End: 35,
  PageUp: 33,
  PageDown: 34,
  Esc: 27
}
