import { decomposeColor, recomposeColor } from '@material-ui/core'

function clamp(value, min = 0, max = 1) {
  if (process.env.NODE_ENV !== 'production') {
    if (value < min || value > max) {
      console.error(`Material-UI: The value provided ${value} is out of range [${min}, ${max}].`)
    }
  }

  return Math.min(Math.max(min, value), max)
}

export function alpha(color, value) {
  color = decomposeColor(color)
  value = clamp(value)

  if (color.type === 'rgb' || color.type === 'hsl') {
    color.type += 'a'
  }
  if (color.type === 'color') {
    color.values[3] = `/${value}`
  } else {
    color.values[3] = value
  }

  return recomposeColor(color)
}
