import classNames from 'clsx'
import { useFormik } from 'formik'
import { cloneDeep } from 'lodash'
import PropTypes from 'prop-types'
import * as Yup from 'yup'

import { useEffect, useMemo, useState } from 'react'
import { FormattedMessage } from 'react-intl'

import Box from '@material-ui/core/Box'
import Checkbox from '@material-ui/core/Checkbox'
import Dialog from '@material-ui/core/Dialog'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import TextField from '@material-ui/core/TextField'
// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles'

import { GLOBAL_FORMAT_DAY } from '@Utils/constants'

import { Dropdown } from 'src/Components/Dropdown/Dropdown'
import { formatDateByTimezone } from 'src/Utils/formatDate'

// Custom CSS
import addDevicesStyles from '../../Common/jss/containers/addDevicesStyle'
import Button from '../../Components/CustomButtons/Button'
import GenerateNum from '../../Components/CustomInput/GenerateNum'
import DialogContent from '../../Components/DialogContent/DialogContent'
import Typography from '../../Components/Typography/Typography'
import { useStore } from '../../Hooks/useStore'
import { getActivationCode, getCodeNumByUser, sendActivationCode } from '../../Service/api'
import { EMAILMAXLENGTH, regEmail } from '../../Utils'
import { wait } from '../../Utils/wait'

const useStyles = makeStyles(addDevicesStyles)

const GenerateActivationCode = props => {
  const { btnTxt = 'Add devices', btnId = 'Btn_Add_Device', className, ...other } = props
  const [open, setOpen] = useState(false)
  const [step, setCurrentStep] = useState('step1')
  const [remain, setRemain] = useState(200)
  const [values, setValues] = useState(0)
  const [codeDetails, setCodeDetails] = useState({})
  const [email, setEmail] = useState('')
  const [dialogInfo, setDialogInfo] = useState({
    title: btnTxt,
    showConfirm: true,
    cancel: true,
    confirmText: 'Next'
  })
  const optionDays = [
    {
      label: '2',
      value: '2'
    },
    {
      label: '5',
      value: '5'
    },
    {
      label: '10',
      value: '10'
    }
  ]

  const [days, setDays] = useState('2')
  const [expireDay, setExpireDay] = useState('')
  const {
    user: { userInfo }
  } = useStore()
  const classes = useStyles()
  // Click the addDevice button to start the event
  const clickAddDeviceHandle = () => {
    setDialogInfo({
      ...cloneDeep(dialogInfo),
      cancel: true
    })
    setOpen(!open)
  }
  // 点击关闭按钮关闭弹框
  const closeHandle = () => {
    const { confirmText } = dialogInfo
    setOpen(false)
    setDays('2')
    wait(500).then(() => {
      if (values) setValues(0)
      if (step !== 'step1') setCurrentStep('step1')
      if (Object.keys(codeDetails).length > 0) setCodeDetails({})
      if (confirmText !== 'Next')
        setDialogInfo({
          ...cloneDeep(dialogInfo),
          confirmText: 'Next'
        })
    })
  }

  useEffect(() => {
    fetchCodeNumByUser()
  }, [JSON.stringify(userInfo)])

  const onChangeHandle = event => {
    event.target.value = event.target.value.replace(/[^\d]/g, '')
    const values = parseInt(event.target.value)
    if (values === 0) {
      event.target.value = 1
    } else if (values >= remain) {
      event.target.value = parseInt(remain, 10)
    }
    setValues(event.target.value)
  }
  // 点击➕、➖触发事件
  const addOrRemoveHandle = target => {
    if (!remain) return false
    const inputValues = values === '' ? 0 : values
    if (target === 'reduce') {
      setValues(inputValues === 0 ? 0 : parseInt(inputValues, 10) - 1)
    } else {
      setValues(inputValues >= remain ? remain : parseInt(inputValues, 10) + 1)
    }
  }

  const onClickHandle = () => {
    if (values === 0) {
      setValues('')
    }
  }

  const onBlurHandle = () => {
    if (values === '') {
      setValues(0)
    }
  }

  const remainNum = useMemo(() => {
    return remain - values
  }, [remain, values])

  // 成功的回调方法
  const successCallback = (values, actions) => {
    const { email } = values
    if (email) setEmail(email)
    actions.resetForm()
    actions.setStatus({ success: true })
    setDialogInfo({
      ...cloneDeep(dialogInfo),
      confirmText: 'Done',
      cancel: false
    })
    setCurrentStep('step3')
  }

  const formBase = useFormik({
    initialValues: {
      fold: true,
      email: userInfo && userInfo.emailAddress ? userInfo.emailAddress : ''
    },
    validationSchema: Yup.object().shape({
      email: Yup.string()
        .max(
          EMAILMAXLENGTH,
          <FormattedMessage
            id="user.input-characters.limit"
            values={{ maxNum: EMAILMAXLENGTH }}
          />
        )
        .matches(regEmail, {
          message: <FormattedMessage id="user.email.wrong-format" />
        })
        .required(<FormattedMessage id="user.email.required" />)
    }),
    onSubmit: async (values, actions) => {
      await fetchSendActivationCode(values, successCallback(values, actions))
    }
  })
  const checkboxChangeHandle = () => {
    const { confirmText } = dialogInfo
    setDialogInfo({
      ...dialogInfo,
      confirmText: confirmText === 'Send codes' ? 'Done' : 'Send codes'
    })
  }
  // 点击确认按钮触发事件
  const confirmHandle = () => {
    const { confirmText } = dialogInfo
    switch (confirmText) {
      case 'Next':
        fetchActivationCode({ needNum: values, days })
        break
      case 'Send codes':
        formBase.handleSubmit()
        break
      case 'Done':
        setOpen(false)
        wait(500).then(() => {
          formBase.setValues({
            fold: true,
            email: userInfo && userInfo.emailAddress ? userInfo.emailAddress : ''
          })
          setValues(0)
          setCurrentStep('step1')
          setCodeDetails({})
          setDialogInfo({ ...cloneDeep(dialogInfo), confirmText: 'Next' })
        })
        break
      default:
        setDialogInfo({ ...cloneDeep(dialogInfo), confirmText: 'Next' })
        setCurrentStep('step1')
    }
  }
  // resend email Handle
  const clickedResendHandle = () => {
    fetchSendActivationCode({ email: email })
  }

  // 获取激活剩余量
  const fetchCodeNumByUser = async () => {
    const { userKey } = userInfo
    if (!userKey) return false
    try {
      const response = await getCodeNumByUser({ userKey: userKey })
      if (response.data.success) {
        const { surplusNum } = response.data.data
        setRemain(surplusNum)
      }
    } catch (e) {
      console.log(e.toString())
    }
  }
  // 获取激活码信息
  const fetchActivationCode = async values => {
    const { userKey } = userInfo
    try {
      const response = await getActivationCode({
        ...values,
        userKey: userKey
      })
      if (response.data.success) {
        const { data } = cloneDeep(response.data)
        const { codeList, gmtExpire } = data
        console.log(`gmtExpire`, gmtExpire)
        const gmtExpireLocal = formatDateByTimezone(gmtExpire, `${GLOBAL_FORMAT_DAY} HH:mm A`)
        console.log('gmtExpireLocal', gmtExpireLocal)
        setExpireDay(gmtExpireLocal)
        codeList.forEach(element => {
          let regex = /(.{3})/g
          let mac = element.code
          mac = mac.replace(regex, '$1-')
          mac = mac.substr(0, mac.length - 1)
          element.code = mac
        })
        setCodeDetails(data)
        setDialogInfo({ ...cloneDeep(dialogInfo), confirmText: 'Send codes' })
        setCurrentStep('step2')
        await fetchCodeNumByUser()
      }
    } catch (e) {
      console.log(e.toString())
    }
  }

  // 发送验证码
  const fetchSendActivationCode = async (values, fulfilledCallback, rejectedCallback) => {
    const { codeList } = codeDetails
    const { userKey } = userInfo
    if (!codeList || !userKey) return false
    const codes = codeList.map(item => item.code)
    try {
      const codeInfo = {
        ...values,
        codes: codes.join(','),
        userKey: userKey,
        days: days,
        gmtExpire: expireDay
      }
      const response = await sendActivationCode(codeInfo)
      if (response.data.success) {
        if (fulfilledCallback) fulfilledCallback()
      }
    } catch (error) {
      if (rejectedCallback) rejectedCallback()
    }
  }

  const handleChangeDays = event => {
    console.log(`event.target.value`, event.target.value)
    setDays(event.target.value)
  }

  const dialogContentDetails = {
    step1: (
      <div className={classes.codesNums}>
        <Typography
          className={classes.desc}
          variant="subtitle2"
          gutterBottom
        >
          Please select or enter the number of device activation code(s) you wish to generate:
        </Typography>
        <div className={classes.content}>
          <GenerateNum
            addOrRemoveHandle={addOrRemoveHandle}
            className={classes.generate}
            value={values}
            onBlurHandle={onBlurHandle}
            onChangeHandle={onChangeHandle}
            onClickHandle={onClickHandle}
          />
          <Typography
            variant="body2"
            className={classNames({
              [classes.regulations]: true,
              [classes.regulation1]: true
            })}
            gutterBottom
          >
            <span className={classes.remain}>{remainNum}</span>
            {` code(s) remain today. Up to 200 codes per day.`}
          </Typography>

          <Typography
            className={classes.expireDaysDescribe}
            component="div"
          >
            The activation code(s) will expire in
            <Dropdown
              className={classes.select}
              inputClassName={classes.input}
              name="country"
              options={optionDays}
              panelClassName={classes.panel}
              placeholder=""
              scrollHeight={'200px'}
              value={days}
              onChange={handleChangeDays}
            />
            days.
          </Typography>
        </div>
      </div>
    ),
    step2: (
      <div className={classes.sendCodesWrapper}>
        <Typography
          className={classes.desc}
          variant="subtitle2"
          gutterBottom
        >
          Enter an activation code below on the screen of each DTEN device you wish to add:
        </Typography>
        <Box className={classes.box}>
          {codeDetails.codeList && codeDetails.codeList.length > 0
            ? codeDetails.codeList.map(item => {
                return (
                  <div
                    key={item.id}
                    className={classes.codeItem}
                  >
                    {/*<span className={classes.serialNumber}>*/}
                    {/*  {index + 1}*/}
                    {/*  {'.'}*/}
                    {/*</span>*/}
                    <span className={classes.code}>{item.code}</span>
                  </div>
                )
              })
            : null}
        </Box>
        <Typography
          variant="body2"
          className={classNames({
            [classes.regulations]: true,
            [classes.setWidth]: true,
            [classes.regulation2]: true
          })}
          gutterBottom
        >
          You can generate up to 200 codes per day.
          <br /> The activation code(s) will expire in {days} days ({expireDay}
          ).
        </Typography>
        <form
          autoComplete="off"
          noValidate
        >
          <FormControlLabel
            label="Share the activation code(s)"
            classes={{
              label: classes.label
            }}
            control={
              <Checkbox
                checked={formBase.values.fold}
                id="fold"
                name="fold"
                classes={{
                  root: classes.checkbox
                }}
                onChange={e => {
                  checkboxChangeHandle(e)
                  formBase.handleChange(e)
                }}
              />
            }
          />
          {formBase.values.fold && (
            <TextField
              autoComplete="email"
              error={Boolean(formBase.touched.email && formBase.errors.email)}
              helperText={formBase.touched.email && formBase.errors.email}
              id="email"
              name="email"
              size="small"
              value={formBase.values.email}
              variant="outlined"
              InputLabelProps={{
                shrink: true
              }}
              InputProps={{
                inputProps: {
                  maxLength: EMAILMAXLENGTH + 1
                }
              }}
              fullWidth
              required
              onBlur={formBase.handleBlur}
              onChange={formBase.handleChange}
            />
          )}
        </form>
      </div>
    ),
    step3: (
      <div className={classes.fulfilled}>
        <Typography
          className={classes.desc}
          variant="subtitle2"
          gutterBottom
        >
          The activation codes have been sent to {email}.
        </Typography>
        <Typography
          className={`${classes.regulations} ${classes.fullwidth}`}
          variant="body2"
          gutterBottom
        >
          If you did not receive the email, click to{' '}
          <span
            className={classes.resend}
            onClick={clickedResendHandle}
          >
            {'resend email'}
          </span>
          .
        </Typography>
      </div>
    )
  }

  return (
    <div
      className={classNames({
        [classes.root]: true,
        [className]: className
      })}
    >
      <Button
        className={classes.addDevice}
        color={'success'}
        id={btnId}
        onClick={clickAddDeviceHandle}
        {...other}
      >
        {btnTxt}
      </Button>

      <Dialog
        aria-describedby="addDevice-description"
        aria-labelledby="addDevice-title"
        className={classes.root}
        maxWidth={'lg'}
        open={open}
      >
        <DialogContent
          cancel={dialogInfo.cancel}
          cancelText="Cancel"
          closeHandle={closeHandle}
          confirm={dialogInfo.showConfirm}
          confirmHandle={confirmHandle}
          confirmText={dialogInfo.confirmText}
          dialogContent={<div className={classes.contentDetails}>{dialogContentDetails[step]}</div>}
          dialogTitle={dialogInfo.title}
          disabled={!values && step === 'step1'}
          showClose={false}
          titleId={'addDevice-title'}
          width="500px"
        />
      </Dialog>
    </div>
  )
}

GenerateActivationCode.propTypes = {
  btnTxt: PropTypes.string,
  btnId: PropTypes.string,
  className: PropTypes.string
}

export default GenerateActivationCode
