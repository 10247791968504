export const roleLists = ['SuperAdmin', 'Owner', 'Admin', 'Member']
export const authorityLists = ['SuperAdmin', 'Owner', 'Admin']

// const replaceDomainAndPort = (domain, port, url) => {
//   let url_bak = ''
//   if (url.indexOf('//') !== -1) {
//     let splitTemp = url.split('//')
//     let url_bak = splitTemp[0] + '//'
//     if (port !== null) {
//       url_bak = url_bak + domain + ':' + port
//     } else {
//       url_bak = url_bak + domain
//     }
//
//     if (splitTemp.length >= 1 && splitTemp[1].indexOf('/') !== -1) {
//       const urlTemp2 = splitTemp[1].split('/')
//       if (urlTemp2.length > 1) {
//         for (let i = 1; i < urlTemp2.length;i++) {
//           url_bak = url_bak + '/' + urlTemp2[i]
//         }
//       }
//       console.log('url_bak:' + url_bak)
//     } else {
//       console.log('url_bak:' + url_bak)
//     }
//   }
//   return url_bak
// }

export const isHaveLoginDomain = (loginDomain = '') => {
  return (
    loginDomain.length > 0 &&
    window.location.hostname.toLowerCase() !== loginDomain.toLowerCase() &&
    process.env.NODE_ENV === 'production'
  )
}

/**
 * @description: 注意拼接c_param参数
 * https://test.service.displayten.com.cn/registration/login?redirect=/device/manage%3Fc_param=WTVXRkVEUHo3bXR4SzM2ZDRUYkhNdnpuakhGaUY0bG95NWJQekRHWU5tbUZKVGQwOWI4RjZnPT0=
 * @return {*}
 */
export const routeToTarget = async (
  isEmpty,
  role,
  history,
  loginDomain = '',
  isBeta = false,
  redirect,
  c_param,
  logActionEmailApi
) => {
  console.log('routeToTarget', redirect)
  // 登录打点
  // 防止接口被cancel
  if (c_param) {
    await logActionEmailApi({ c_param })
  }
  if (redirect?.length > 0) {
    if (isHaveLoginDomain(loginDomain)) {
      localStorage.clear()
      if (redirect.indexOf('http') === 0) {
        // eslint-disable-next-line no-irregular-whitespace
        const redirectOrigin = new URL(redirect).origin
        const redirectHref = redirect.replace(redirectOrigin, `https://${loginDomain}`)
        window.location.href = redirectHref
      } else {
        window.location.href = `https://${loginDomain}${redirect}`
      }
    } else {
      if (redirect.indexOf('http') === 0) {
        window.location.href = redirect
      } else {
        history.push(redirect)
        console.log('OK', redirect)
      }
    }
    return false
  }

  console.log('routeToTarget no redirect', redirect)

  if (role === 'Member') {
    if (isHaveLoginDomain(loginDomain)) {
      localStorage.clear()
      window.location.href = `https://${loginDomain}/equipment`
    } else {
      isBeta ? (window.location.href = `${window.location.origin}/equipment`) : history.push('/equipment')
    }
  } else {
    if (isEmpty === 1) {
      if (isHaveLoginDomain(loginDomain)) {
        localStorage.clear()
        window.location.href = `https://${loginDomain}/account`
      } else {
        isBeta ? (window.location.href = `${window.location.origin}/account`) : history.push('/account')
      }
    } else {
      if (isHaveLoginDomain(loginDomain)) {
        localStorage.clear()
        window.location.href = `https://${loginDomain}/dashboard`
      } else {
        isBeta ? (window.location.href = `${window.location.origin}/dashboard`) : history.push('/dashboard')
      }
    }
  }
  return false
}
