export default {
  'user.login.email': '邮箱',
  'user.login.password': '密码',
  'user.login.remember-me': '自动登录',
  'user.login.do-not-have-account': '没有帐户?',
  'user.login.forgot-password': '忘记密码',
  'user.login.signup': '注册账户',
  'user.login.login': '登录',
  'user.register.register': '注册',
  'user.reset.link-expired': '链接失效',
  'user.register.first-name': '姓',
  'user.register.last-name': '名',
  'user.register.company-name': '公司名称',
  'user.register.confirm-password': '确认密码',
  'user.register.show': '显示',
  'user.register.hide': '隐藏',
  'user.register.sign-in': '已有账户',
  'user.register-result.activation-email':
    '激活邮件已发送到你的邮箱中，邮件有效期为24小时。请及时登录邮箱，点击邮件中的链接激活帐户。',
  'user.email.required': '请输入邮箱地址！',
  'user.email.wrong-format': '邮箱地址格式错误！',
  'user.password.wrong-format': '密码至少包含一个大写字母、一个小写字母和一个数字,且长度不能小于6个字符',
  'user.password.required': '请输入密码！',
  'user.password.twice': '两次输入的密码不匹配!',
  'user.first-name.required': '请输入姓',
  'user.last-name.required': '请输入名字',
  'user.company-name.required': '请输入公司名称',
  'user.confirm-password.required': '请确认密码！',
  'user.phone-number.required': '请输入手机号！',
  'user.phone-number.wrong-format': '手机号格式错误！',
  'user.password-reset.rest': '密码重置',
  'user.password-reset.desc': '请输入您的电子邮件，我们将发送一个重设密码的链接',
  'user.password-rest-send': '确认',
  'user.form.submit': '提交',
  'user.form.save': '保存',
  'user.navBar.lang': '语言',
  'user.activate.account-title': '激活个人DTEN账号',
  'user.activate.account-subtitle': '输入密码完成注册',
  'user.okta.back': '返回'
}
