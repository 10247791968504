import classNames from 'clsx'
import PropTypes from 'prop-types'

import { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'

// @material-ui/core components
import AppBar from '@material-ui/core/AppBar'
import Drawer from '@material-ui/core/Drawer'
import Hidden from '@material-ui/core/Hidden'
import { makeStyles } from '@material-ui/core/styles'
import Close from '@material-ui/icons/Close'
// @material-ui/icons
import Menu from '@material-ui/icons/Menu'

import Logo from '@Common/images/orbitLogo.png'
// CSS Module
import headerStyle from '@Common/jss/components/headerStyle'

import Button from '../CustomButtons/Button'
// Custom Components
import Toolbar from '../Toolbar/Toolbar'

const useStyles = makeStyles(headerStyle)
const Header = props => {
  const classes = useStyles()
  const [mobileOpen, setMobileOpen] = useState(false)
  useEffect(() => {
    if (props.changeColorOnScroll) {
      window.addEventListener('scroll', headerColorChange)
    }
    return function cleanup() {
      if (props.changeColorOnScroll) {
        window.removeEventListener('scroll', headerColorChange)
      }
    }
  }, [])
  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen)
  }
  const headerColorChange = () => {
    const { color, changeColorOnScroll } = props

    const windowsScrollTop = window.pageYOffset
    console.log(windowsScrollTop)
    console.log(changeColorOnScroll.height)
    if (windowsScrollTop > changeColorOnScroll.height) {
      document.body.getElementsByTagName('header')[0].classList.remove(classes[color])
      document.body.getElementsByTagName('header')[0].classList.add(classes[changeColorOnScroll.color])
    } else {
      document.body.getElementsByTagName('header')[0].classList.add(classes[color])
      document.body.getElementsByTagName('header')[0].classList.remove(classes[changeColorOnScroll.color])
    }
  }
  const { color, links, brand, fixed, absolute } = props
  const appBarClasses = classNames({
    [classes.appBar]: true,
    [classes[color]]: color,
    [classes.absolute]: absolute,
    [classes.fixed]: fixed
  })
  return (
    <AppBar className={appBarClasses}>
      <Toolbar className={classes.toolbarContainer}>
        <Link
          className={classes.title}
          to="/"
        >
          <img
            alt={'Logo'}
            className={classes.logo}
            src={Logo}
          />
          {brand}
        </Link>
        <Hidden
          className={classes.hidden}
          implementation="css"
          smDown
        >
          <div className={classes.collapse}>{links}</div>
        </Hidden>
        <Hidden mdUp>
          <Button
            aria-label="open drawer"
            color={'transparent'}
            justIcon
            onClick={handleDrawerToggle}
          >
            <Menu />
          </Button>
        </Hidden>
        <Hidden
          implementation="js"
          mdUp
        >
          <Drawer
            anchor={'right'}
            open={mobileOpen}
            variant="temporary"
            classes={{
              paper: classes.drawerPaper
            }}
            onClose={handleDrawerToggle}
          >
            <Button
              aria-label="open drawer"
              className={classes.closeButtonDrawer}
              color={'transparent'}
              size={'sm'}
              justIcon
              onClick={handleDrawerToggle}
            >
              <Close />
            </Button>
            {links && <div className={classes.appResponsive}>{links}</div>}
          </Drawer>
        </Hidden>
      </Toolbar>
    </AppBar>
  )
}

Header.defaultProp = {
  color: 'white'
}

Header.propTypes = {
  color: PropTypes.oneOf(['primary', 'info', 'success', 'warning', 'danger', 'transparent', 'white', 'rose', 'dark']),
  links: PropTypes.node,
  brand: PropTypes.string,
  fixed: PropTypes.bool,
  absolute: PropTypes.bool,
  changeColorOnScroll: PropTypes.shape({
    height: PropTypes.number.isRequired,
    color: PropTypes.oneOf(['primary', 'info', 'success', 'warning', 'danger', 'transparent', 'white', 'rose', 'dark'])
      .isRequired
  })
}
export default Header
