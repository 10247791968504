import { GLOBAL_FORMAT_SECOND } from '@Utils/constants'

import { getDtenDeviceModel } from 'src/Utils/device'
import { formatDateByTimezone } from 'src/Utils/formatDate'

// 判断是否显示upgrade按钮
// 离线指令只支持 mqtt 协议
export const showUpgradeBtn = rowInfo => {
  if (getDtenDeviceModel(rowInfo).isMatePair) {
    return false
  }
  const installStatus = [0, 4, 5, 8]
  const { hasNewVersion, deviceInstallStatus, supportNewUpgradeFlow } = rowInfo
  if (!hasNewVersion) {
    return false
  }
  return supportNewUpgradeFlow
    ? hasNewVersion === 1 && deviceInstallStatus === 8
    : hasNewVersion === 1 && installStatus.includes(deviceInstallStatus)
}

export const showUpgradeBtnD7 = rowInfo => {
  const installStatus = [0, 4, 5, 8]
  const { deviceInstallStatus, supportNewUpgradeFlow } = rowInfo
  return supportNewUpgradeFlow ? deviceInstallStatus === 8 : installStatus.includes(deviceInstallStatus)
}

// os version 是否橘色 是否显示升级按钮和升级中,是否有新版本
// 3和10是升级中
// 8有新版本
export const showNewVersionOrange = rowInfo => {
  if (getDtenDeviceModel(rowInfo).isMatePair) {
    return false
  }
  const installStatus = [0, 1, 2, 3, 4, 5, 6, 8, 10]
  const { hasNewVersion, deviceInstallStatus, supportNewUpgradeFlow } = rowInfo
  if (!hasNewVersion) {
    return false
  }
  return supportNewUpgradeFlow ? [3, 8, 10].includes(deviceInstallStatus) : installStatus.includes(deviceInstallStatus)
}

/**
 * @description:
 * 旧版本升级包安装状态（0:正常 1:检测中，2：下载中，3：安装中，4：成功，5：下载失败/安装失败, 6阻塞 ,10 已下发升级指令）
 * 新版本：supportNewUpgradeFlow = true , deviceInstallStatus 为 3、10代表升级中 8 是有新版本客户端已经下载
 * hasNewVersion 新版本
 * @param {*} item
 * @return {*}
 */
export const isUpdating = item => {
  if (getDtenDeviceModel(item).isMatePair) {
    return false
  }
  const status = [1, 2, 3, 6, 10]
  const { hasNewVersion, deviceInstallStatus, supportNewUpgradeFlow } = item
  if (!hasNewVersion) {
    return false
  }
  return supportNewUpgradeFlow ? [3, 10].includes(deviceInstallStatus) : status.includes(deviceInstallStatus)
  // return status.includes(deviceInstallStatus)
}

export const isUpdatingD7 = item => {
  const status = [1, 2, 3, 6, 10]
  const { deviceInstallStatus, supportNewUpgradeFlow } = item
  return supportNewUpgradeFlow ? [3, 10].includes(deviceInstallStatus) : status.includes(deviceInstallStatus)
}

/**
 * @description: 显示升级app按钮
 * @param {*}
 * @return {*}
 * 预计改为8
 */
export const showUpgradeBtnApp = rowInfo => {
  return rowInfo.zoomUpgradeInfo?.installStatus === 8
}

export const showNewVersionOrangeApp = rowInfo => {
  return !!rowInfo.zoomUpgradeInfo
}

// 预计改为3
// installStatus 0:正常 1:检测中，2：下载中，3：安装中，4：成功，5：下载失败/安装失败 8:已下载待安装
export const isUpdatingApp = (installStatus = 0) => {
  // return item.zoomUpgradeInfo?.installStatus === 3
  return installStatus === 3
}

// 判断D7
export const isD7 = (device = '') => {
  return device === 'D7'
}

export const fillGMT = timezone => {
  return /^GMT*$/g.test(timezone) ? timezone : `GMT${timezone}`
}

// 是否显示升级进度(D7 不支持进度)
export const showInstallProgress = rowInfo => {
  return rowInfo.device !== 'D7'
}

/**
 * @description: 显示升级耗时根据设备类型
 * 规则：
 * D7 75  55 3458  10分钟
 * D7 55 648 30分钟
 * ME GO ON onTV 等Android 10分钟
 * ==================
 * 判断依据：
 * device-device_type
 * D7 75 55 3458 ：D7-002
 * D7 55 648 D7-opsx03
 * ME GO ON onTV  S1-xxx
 * @param {*} rowInfo
 * @return {*}
 *
 */
export const showInstallCostTime = rowInfo => {
  const timeCost = {
    1: 'estimated time: 10 minutes',
    2: 'estimated time: 30 minutes'
  }
  if (rowInfo.device === 'D7' && rowInfo.deviceType === '002') {
    return timeCost['1']
  } else if (rowInfo.device === 'D7' && rowInfo.deviceType === 'opsx03') {
    return timeCost['2']
  } else if (rowInfo.device === 'S1') {
    return timeCost[1]
  } else {
    return ''
  }
}

// 是否disabled Repair按钮 GoWithMate不支持 netty离线不支持
export function showRepair(rowInfo) {
  if (isGoWithMate(rowInfo)) {
    return true
  }
  const { checkErrorCodes } = rowInfo
  return !checkErrorCodes
}

// 是否禁用repair按钮, repair 和 quick chekc 互斥
// repairStatus 0 待修复 1 repairing 2 修复失败
// 增加 repairing === 1 不允许再次修复
// eslint-disable-next-line no-unused-vars
export function disableRepair({ rowInfo, allHealthErrCodes = [] }) {
  if (getDtenDeviceModel(rowInfo).dtenModel === 'Mate') {
    return true
  }
  if (isGoWithMate(rowInfo)) {
    return true
  }
  if (rowInfo.repairStatus === 1 || rowInfo.checkStatus === '1') {
    return true
  }
  const { checkErrorCodes } = rowInfo
  // return !checkErrorCodes
  if (!checkErrorCodes) {
    return true
  }
  return !isExistCanRepair({ checkErrorCodes, allHealthErrCodes })
}

/**
 * @description:
 * getAllError 返回的对应的错误的actions字段存在并且等于“1或者“2”, 才可以Repair
 * 只要有一条errorid满足，即返回true
 * @param {*}
 * @return {*}
 */
export function isExistCanRepair({ checkErrorCodes = '', allHealthErrCodes = [] }) {
  const ENABLEACTION = ['1', '2']
  if (!checkErrorCodes.includes(',')) {
    return allHealthErrCodes.some(item => ENABLEACTION.includes(item?.actions) && item.errorId === checkErrorCodes)
  } else {
    const checkErrorCodesArray = checkErrorCodes.split(',')
    let result = false
    checkErrorCodesArray.forEach(item => {
      if (allHealthErrCodes.some(i => ENABLEACTION.includes(i?.actions) && i.errorId === item)) {
        result = true
      }
    })
    return result
  }
}

/* 
  checkStatus
	自检流转状态（0：未开始自检，1：自检中，2：自检成功，3：自检失败，4：自检超时）
*/
// 是否显示Report Issue按钮 true 置灰
export function showReportIssue(rowInfo) {
  if (getDtenDeviceModel(rowInfo).dtenModel === 'Mate') {
    return true
  }
  const status = [3, 4]
  const { planId, checkStatus } = rowInfo
  const canShow = planId === 3 && status.includes(parseInt(checkStatus))
  const disable = !canShow
  return disable
}

/**
 * @description: 是否显示Quick Check按钮
 * @param {*} rowInfo
 * @return {*} true 不显示 false 显示
 */
export function showQuickCheckDashboard(rowInfo) {
  if ([`Mate`, `GO`].includes(getDtenDeviceModel(rowInfo).dtenModel) || rowInfo.idleStatus) {
    return true
  }

  // const { idleStatus } = rowInfo
  // return !(deviceInstallStatus !== 3 && !idleStatus && checkStatus !== '1')
  // return deviceInstallStatus === 3 || idleStatus
  return false
}

/**
 * @description: 显示quickcheck功能是不可用还是设备忙碌
 * @param {*} rowInfo
 * @return {*}
 */
export function showQuickCheckDashboardDisabledReason(rowInfo) {
  if ([`Mate`, `GO`].includes(getDtenDeviceModel(rowInfo).dtenModel)) {
    return DISABLEDREASON
  }
  const { idleStatus } = rowInfo
  return idleStatus ? BUSY : null
}

/**
 * @description: repair 和 quick chekc 互斥，修复中 不允许quickcheck，quickchecking 中不允许repair
 * @param {*} rowInfo
 * @return {*}
 */
export function showQuickCheck(rowInfo) {
  if (getDtenDeviceModel(rowInfo).dtenModel === 'Mate') {
    return true
  }
  if (isGoWithMate(rowInfo)) {
    return true
  }
  const { checkStatus, repairStatus } = rowInfo
  // return !(deviceInstallStatus !== 3 && !idleStatus && checkStatus !== '1')
  return checkStatus === '1' || repairStatus === 1
}

export function showQuickCheckDisabledReason(rowInfo) {
  if (isGoWithMate(rowInfo)) {
    return DISABLEDREASON
  }
  const { idleStatus } = rowInfo
  return idleStatus ? BUSY : null
}

/**
 * @description: // 是否显示 Remove 按钮Disable
 * D7 需要特殊处理，依据 canInactivate 参数
 * @param {*}
 * @return {*}
 */
export function showRemove(rowInfo) {
  const { device, canInactivate, pairInfo } = rowInfo

  if (pairInfo && pairInfo?.device === 'R1' && pairInfo?.deviceType === '003') {
    return true
  } else {
    return !(device === 'D7' ? canInactivate : 1)
  }
}

export function showAssign() {
  return false
}

// 操作按钮状态
export const btnTips = {
  textChange: ['Save', 'Saving', 'Saved']
}

export const BUTTON_DELAY_TIME = 1500

// 判断是否go & mate
export const isGoWithMate = (devicesInfo = { deviceType: '', hasAccessory: 0 }) => {
  return devicesInfo.deviceType === '005' && devicesInfo.hasAccessory === 1
}

// D7 003,S1 005 都可以配Mate
export const isWithMate = (devicesInfo = { deviceType: '', hasAccessory: 0 }) => {
  return ['005', '003', '001'].includes(devicesInfo.deviceType) && devicesInfo.hasAccessory === 1
}

/**
 * @description:
 * D7 003 ,D7 005, S1 003,S1 005, R1 001
 * @return {*}
 */
export const isCanWithMate = devicesInfo => {
  return (
    (devicesInfo.device === 'D7' && devicesInfo.deviceType === '003') ||
    (devicesInfo.device === 'D7' && devicesInfo.deviceType === '005') ||
    (devicesInfo.device === 'S1' && devicesInfo.deviceType === '005') ||
    (devicesInfo.device === 'R1' && devicesInfo.deviceType === '001')
  )
}

export const isMate = devicesInfo => {
  return devicesInfo?.device === 'Mate'
}

// netty 协议 离线设备
export const isNettyOffline = devicesInfo => {
  const { deviceLinkSource = '', status = 0 } = devicesInfo
  return deviceLinkSource?.toLowerCase() !== 'mqtt' && status === 0
}

// 升级按钮是否显示 netty协议离线和 go with mate 离线，都不支持
export const isGoMateOfflineOrNetty = devicesInfo => {
  const { deviceType, hasAccessory, deviceLinkSource, status } = devicesInfo
  return (
    (isGoWithMate({
      deviceType,
      hasAccessory
    }) &&
      status === 0) ||
    isNettyOffline({ deviceLinkSource, status })
  )
}

// go & mate, mate是否在线
export const isMateOnline = (accessories = [{ onlineStatus: 0 }]) => {
  return accessories.length > 0 && accessories[0].onlineStatus === 1
}

/**
 * @description: go & mate, mate是否在线
 * @param {*} 列表页缺少accessories字段，详情页缺少mateOnlineStatus字段
 * @return {*}
 */
export const isMateOnlinePlus = (mateOnlineStatus, accessories) => {
  if (typeof mateOnlineStatus === 'number') {
    return mateOnlineStatus === 1
  } else {
    const result = accessories?.length > 0 && accessories[0]?.onlineStatus === 1
    return result
  }
}

export const getCheckErrorCodesText = (checkErrorCodes = '') => {
  return checkErrorCodes.replace(/,/g, ', ')
}

/**
 * @description: 分割字符
 * @param {*} str 字符串
 * @param {*} size 分割长度
 * @return {*}
 */
export const chunk = (str, size) => {
  return str.match(new RegExp(`.{1,${size}}`, 'g'))
}

export const chunkStr = (str, size, flag) => {
  return chunk(str, size).join(flag)
}

/**
 * @description: setting界面 按钮是否可点击 离线指令只支持 mqtt 协议
 * statue === 1 在线不区分是否mqtt， 离线情况下只支持mqtt
 * @param {*}
 * @return {*}
 */
export const isCanClickAlpha = (available = false, status = 0, deviceLinkSource = 'netty') => {
  if (available) {
    if (status === 1) {
      return true
    } else if (deviceLinkSource?.toLowerCase() === 'mqtt' && status === 0) {
      return true
    } else {
      return false
    }
  } else {
    return false
  }
}

/**
 * @description: setting界面 离线指令按钮是否可点击
 * @param {*} 不区分 mqtt netty都可以点击
 * @return {*}
 */
export const isCanClick = (available = false) => {
  return available
}

/**
 * @description: 根据在线 status 和available 判断按钮是否可以操作
 * @param {*} available
 * @param {*} status
 * @return {*}
 */
export const isCanClickNetStatus = (available = false, status) => {
  return available && status === 1
}

/**
 * @description: dashboard 是否升级按钮是否可以点击
 * @param {*}
 * @return {*}
 */
export const isCanClickUpdate = (status = 0, deviceLinkSource = 'netty') => {
  if (status) {
    return true
  } else {
    return deviceLinkSource?.toLowerCase() === 'mqtt' && status === 0
  }
}

/**
 * @description: 是否离线的 mqtt 协议
 * @param {*} status
 * @param {*} deviceLinkSource
 * @return {*}
 */
export const isMqttOffline = (status = 0, deviceLinkSource = 'netty') => {
  return deviceLinkSource?.toLowerCase() === 'mqtt' && status === 0
}

/**
 * @description: 离线指令执行成功弹窗
 * @param {*} isAll true ｜ false  为true不用区分是否 MQTT
 * @return {*}
 */
export const showOfflineInsPrompt = (offlineObj = null, moduleCodes = [], isAll = false, callback) => {
  if (offlineObj) {
    const { status, deviceLinkSource, keyCodeId } = offlineObj
    if (isAll) {
      if (status === 0 && moduleCodes.includes(keyCodeId)) {
        callback()
      }
    } else {
      if (isMqttOffline(status, deviceLinkSource) && moduleCodes.includes(keyCodeId)) {
        callback()
      }
    }
  }
}

/**
 * @description: 健康检查错误文案
 * @param {*}
 * @return {*}
 */
export const getHealthErrLabel = (errId = '', healthErrCodes = {}, checkTime = '') => {
  const label = healthErrCodes[errId] || ''
  return `${errId}  ${label}  ${checkTime}`
}

/**
 * @description: 获取错误码对应的文案
 * @param {*}
 * @return {*}
 */
export const getHealthErr = ({ errId = '', healthErrCodes = {} }) => {
  const label = healthErrCodes[errId] || ''
  return `${label}`
}

/**
 * @description: dashboard 自动填充tickert subject
 * @param {*}
 * @return {*}
 */
export const getTicketSubject = (dataItem, healthErrCodes) => {
  const { checkErrorCodes } = dataItem
  if (checkErrorCodes === '') {
    return null
  }
  if (checkErrorCodes.indexOf(',') === -1) {
    return getHealthErr({ errId: checkErrorCodes, healthErrCodes })
  }
  const errs = checkErrorCodes.split(',')
  return getHealthErr({ errId: errs[0], healthErrCodes })
}

/**
 * @description: dashboard ticket 弹窗 填入 issues msg
 * @param {*}
 * @return {*}
 */
export const checkErrorMsg = (dataItem, healthErrCodes) => {
  const { checkStatus, checkErrorCodes, checkTime } = dataItem
  if (['3', '4'].includes(checkStatus)) {
    if (checkErrorCodes === '') {
      return null
    }
    if (checkErrorCodes.indexOf(',') === -1) {
      return `${dataItem.deviceName}: ${getHealthErrLabel(checkErrorCodes, healthErrCodes, checkTime)}`
    }
    let msg = `${dataItem.deviceName}: `
    const errs = checkErrorCodes.split(',')
    errs.map((error, index) => {
      msg += getHealthErrLabel(error, healthErrCodes, checkTime)
      if (index < errs.length - 1) {
        msg += ',  '
      }
    })
    return msg
  } else {
    return ''
  }
}

export const DISABLEDREASON = `This feature is not supported in the device version and will be available in a future release.`

export const BUSY = `Device is busy. Please operate when the device is free.`

export const BUSYING = `Device is busy and cannot be restarted.`

// 设备电流频率 忙绿提示
export const BUSY_CAMERA_REFRESH_FREQ = `Device is busy and cannot be configured.`
// Display Resolution
export const BUSY_DISPLAY_RESOLUTION = `The device is busy. Please try again later.`

/**
 * @description: 是否有离线设备
 * @param {*}
 * @return {*}
 */
export const hasOfflineItem = dataItems => {
  const hasOffline = dataItems?.some(item => {
    return item.status === 0
  })
  return hasOffline
}

/**
 * @description: 数组中是否有离线的mqtt设备
 * @param {*}
 * @return {*}
 */
export const hasOfflineMqttItem = dataItems => {
  const hasOffline = dataItems?.some(item => {
    return item?.deviceLinkSource?.toLowerCase() === 'mqtt' && item.status === 0
  })
  return hasOffline
}

/**
 * @description: 数组中是否有至少一台可以发离线升级OS指令的设备
 * 离线 非netty协议，并且不能是go with mate
 * @param {*}
 * @return {*}
 */
export const hasOfflineCmdItemOs = dataItems => {
  console.log(`dataItems ==>`, dataItems)
  const hasOffline = dataItems?.some(item => {
    return (
      item?.deviceLinkSource?.toLowerCase() === 'mqtt' &&
      item.status === 0 &&
      !isGoWithMate({
        deviceType: item?.deviceType,
        hasAccessory: item?.hasAccessory
      })
    )
  })
  return hasOffline
}

/**
 * @description: 数组中是否有至少一台可以发离线升级App指令的设备
 * 离线 非netty协议，并且不能是go with mate
 * @param {*}
 * @return {*}
 */
export const hasOfflineCmdItemApp = dataItems => {
  console.log(`dataItems ==>`, dataItems)
  const hasOffline = dataItems?.some(item => {
    return item.status === 0
  })
  return hasOffline
}

/**
 * @description: 数组是否全是离线的mqtt设备
 * @param {*}
 * @return {*}
 */
export const isAllOfflineMqttItem = dataItems => {
  const isAllOfflineList = dataItems?.filter(item => {
    return item?.deviceLinkSource?.toLowerCase() === 'mqtt' && item.status === 0
  })

  return isAllOfflineList.length === dataItems.length && dataItems.length > 0
}

export const productOptions = [
  {
    label: 'All',
    value: 'All'
  },
  {
    label: 'DTEN D7 75',
    value: ''
  },
  {
    label: 'DTEN D7 55',
    value: ''
  },
  {
    label: 'DTEN D7 55 Dual',
    value: ''
  },
  {
    label: 'DTEN D7 75 Dual',
    value: ''
  },
  // {
  //   label: 'DTEN D7',
  //   value: 'D7'
  // },
  {
    label: 'DTEN ME',
    value: 'S1004'
  },
  {
    label: 'DTEN ME Pro',
    value: 'S1004-Pro'
  },
  {
    label: 'DTEN ON',
    value: 'S1003'
  },
  {
    label: 'DTEN GO with Mate',
    value: 'S1005'
  }
]

export const productQueryOptionsTree = [
  {
    id: 'All',
    label: 'All',
    value: 'All'
  },
  {
    id: 'D7-0',
    label: 'DTEN D7',
    value: 'D7-0'
    // children: [
    // 	// {
    //   //   id: 'D7-0-75',
    //   //   label: 'DTEN D7',
    //   //   value: 'D7-0-75'
    //   // },
    //   // {
    //   //   id: 'D7-0-55',
    //   //   label: 'DTEN D7 Dual',
    //   //   value: 'D7-0-55'
    //   // },
    //   // {
    //   //   id: 'D7-0-75',
    //   //   label: 'DTEN D7 75',
    //   //   value: 'D7-0-75'
    //   // },
    //   // {
    //   //   id: 'D7-0-55',
    //   //   label: 'DTEN D7 55',
    //   //   value: 'D7-0-55'
    //   // },
    //   // {
    //   //   id: 'D7-1-55',
    //   //   label: 'DTEN D7 55 Dual',
    //   //   value: 'D7-1-55'
    //   // },
    //   // {
    //   //   id: 'D7-1-75',
    //   //   label: 'DTEN D7 75 Dual',
    //   //   value: 'D7-1-75'
    //   // }
    // ]
  },
  {
    id: 'D7-1',
    label: 'DTEN D7 Dual',
    value: 'D7-1'
  },
  {
    id: 'S1004',
    label: 'DTEN ME',
    value: 'S1004'
  },
  {
    id: 'S1004-Pro',
    label: 'DTEN ME Pro',
    value: 'S1004-Pro'
  },
  {
    id: 'S1003',
    label: 'DTEN ON',
    value: 'S1003'
  },
  {
    id: 'S1005',
    label: 'DTEN GO with Mate',
    value: 'S1005'
  },
  {
    id: 'Mate',
    label: 'DTEN Mate',
    value: 'Mate'
  },
  {
    id: 'R1-3',
    label: 'DTEN Bar',
    value: 'R1-3'
  },
  {
    id: 'onboard-D7',
    label: 'DTEN ONboard Windows',
    value: 'onboard-D7'
  },
  {
    id: 'onboard-Android',
    label: 'DTEN ONboard Android',
    value: 'onboard-Android'
  },
  {
    id: 'D7X-Windows',
    label: 'DTEN D7X Windows Edition',
    value: 'D7X-Windows'
  },
  {
    id: 'D7X-Windows-Dual',
    label: 'DTEN D7X Dual Windows Edition',
    value: 'D7X-Windows-Dual'
  },
  {
    id: 'R1-ON',
    label: 'DTEN D7X Android Edition',
    value: 'R1-ON'
  }
]

export const systemQueryOptions = [
  {
    label: 'All',
    value: 'All'
  },
  {
    label: 'Windows',
    value: 'Windows'
  },
  {
    label: 'Android',
    value: 'Android'
  }
]

export const statusQueryOptionsTree = [
  {
    id: 'All',
    label: 'All',
    value: ''
  },
  {
    id: '1',
    label: 'Online',
    value: '1',
    children: [
      {
        id: '11',
        label: 'Busy',
        value: '11'
      },
      {
        id: '10',
        label: 'Available',
        value: '10'
      }
    ]
  },
  {
    id: '0',
    label: 'Offline',
    value: '0'
  }
]
/* 
  { key: 'All', label: 'All', value: '' },
  { key: 'Healthy', label: 'Healthy', value: 3 },
  { key: 'Issues Found', label: 'Issues Found', value: 4 },
  { key: 'Unchecked', label: 'Unchecked', value: 6 }
*/
export const typeQueryOptions = [
  {
    label: 'All',
    value: ''
  },
  {
    label: 'Healthy',
    value: 3
  },
  {
    label: 'Issues Found',
    value: 4
  },
  {
    label: 'Unchecked',
    value: 6
  }
]

// 修复操作，动画执行时间
export const REPAIRANIMATIONTIME = 3000

/**
 * @description: step 转圈步长， stepTime 一步耗时，maxProgress 进度阈值
 * @param {*}
 * @return {*}
 */
export const REPAIRANIMATION = {
  step: 10,
  stepTime: 3000,
  maxProgress: 90
}

export const formatAnimateStartTime = (val = 0) => {
  return Math.round(val / REPAIRANIMATION.step) * REPAIRANIMATION.step
}

// 获取修复进度，最多到90
export const getProgressPast = repairTimeGreenwich => {
  if (!repairTimeGreenwich) {
    return 0
  }
  const repairLocalTime = formatDateByTimezone(repairTimeGreenwich, GLOBAL_FORMAT_SECOND)

  console.log(`repairLocalTime`, repairLocalTime)

  const repairPast = new Date().getTime() - new Date(repairLocalTime).getTime()

  const repairAllCost = (100 / REPAIRANIMATION.step) * REPAIRANIMATION.stepTime

  console.log('总时长', repairAllCost)
  let repairProgress = repairPast > repairAllCost ? 100 : Math.round((repairPast / repairAllCost) * 100)
  repairProgress = repairProgress >= REPAIRANIMATION.maxProgress ? REPAIRANIMATION.maxProgress : repairProgress
  repairProgress = formatAnimateStartTime(repairProgress)
  console.log('repairProgress', repairProgress)
  return repairProgress
}

export const classificationOptions = [
  {
    label: 'All',
    value: ''
  },
  {
    label: 'None',
    value: 0
  },
  {
    label: 'Security',
    value: 1
  },
  {
    label: 'Critical',
    value: 2
  }
]

export const manageAuthorizationOptions = [
  {
    label: 'All',
    value: ''
  },
  {
    label: 'Pending Approval',
    value: 'Waiting'
  },
  {
    label: 'Approved',
    value: 'Agreed'
  },
  {
    label: 'Rejected',
    value: 'Refusal'
  },
  {
    label: 'Request Expired',
    value: 'Expired'
  }
]

export const TOAST_TEXT = 'Your settings have been updated'

export const transFormAlertEmailParams = params => {
  let arrParams = []
  Object.keys(params).forEach(function (e) {
    arrParams.push({ fieldLeable: e, fieldValue: params[e] })
  })
  return arrParams
}

export const AGREEMENT_OPTIONS = [
  {
    label: 'All',
    value: ''
  },
  {
    label: 'Pending',
    value: 0
  },
  {
    // 过期失败
    label: 'Expired',
    value: 3
  },
  {
    // 成功生效
    label: 'Accepted',
    value: 1
  },
  {
    // 拒绝失败
    label: 'Rejected',
    value: 4
  },
  {
    // 撤销
    label: 'Canceled',
    value: 5
  },
  {
    // 废弃
    label: 'Replaced',
    value: -1
  }
]

export const SERVICE_STATUS = [
  {
    label: 'Not_Started',
    value: 0
  },
  {
    label: 'In_Progress',
    value: 1
  },
  {
    label: 'Service_Expired',
    value: 2
  }
]
