import { useUtils } from '../Hooks/useUtils'

export const defaultMinDate = new Date('1900-01-01')
export const defaultMaxDate = new Date('2099-12-31')

export const setQuickRangeLists = () => {
  const date = useUtils().date()
  // const now = useNow()
  return [
    {
      label: 'Today',
      values: [date.startOf('day'), date.startOf('day')]
    },
    {
      label: 'Yesterday',
      values: [date.startOf('day').subtract(1, 'day'), date.startOf('day').subtract(1, 'day')]
    },
    {
      label: 'Last 7 days',
      values: [date.startOf('day').subtract(7, 'day'), date.startOf('day').subtract(1, 'day')]
    },
    // {
    //   label: 'Last 2 weeks',
    //   values: [date.startOf('day').subtract(13, 'day'), date.startOf('day')]
    // },
    {
      label: 'Last 30 days',
      values: [date.startOf('day').subtract(30, 'day'), date.startOf('day').subtract(1, 'day')]
    },
    // {
    //   label: 'Last Month',
    //   values: [
    //     date.startOf('month').subtract(1, 'month'),
    //     date.endOf('month').subtract(1, 'month').endOf('month')
    //   ]
    // },
    {
      label: 'This Month',
      values: [date.startOf('month'), date.startOf('day').subtract(1, 'day')]
    }
    // {
    //   label: 'Last 6 Months',
    //   values: [date.startOf('month').subtract(5, 'month'), date.startOf('day')]
    // }
  ]
}

export const onSpaceOrEnter = (innerFn, onFocus) => event => {
  if (event.key === 'Enter' || event.key === ' ') {
    innerFn()
    event.preventDefault()
    event.stopPropagation()
  }

  if (onFocus) {
    onFocus(event)
  }
}

export function createDelegatedEventHandler(fn, onEvent) {
  return event => {
    fn(event)
    if (onEvent) {
      onEvent(event)
    }
  }
}

export const doNothing = () => {}
