import classNames from 'clsx'
import PropTypes from 'prop-types'

// @material-ui/core components
import Link from '@material-ui/core/Link'
import { makeStyles } from '@material-ui/core/styles'
import { Email, Phone } from '@material-ui/icons'

import capitalize from '@Utils/capitalize'

// Css In Js
import footerStyle from '@Common/jss/components/footerStyle'

import { SUPPORT_EMAIL, SUPPORT_PHONE } from 'src/Utils/constants'

const useStyles = makeStyles(footerStyle)

const ActivateFooter = props => {
  const { position = 'absolute', border = false, isScan = false } = props
  const classes = useStyles()
  const footerClassName = classNames({
    [classes.footer]: true,
    [classes.border]: border,
    [classes[`position${capitalize(position)}`]]: position
  })
  return (
    <footer className={footerClassName}>
      <div
        className={classes.footerContent}
        style={isScan ? { fontSize: 12 } : {}}
      >
        {isScan ? null : (
          <Link
            className={classes.link}
            color="inherit"
            href="https://help.dten.com/hc/en-us"
            rel="noopener"
            target="_blank"
            underline="always"
          >
            Support
          </Link>
        )}

        <div
          className={classNames({
            [classes.address]: !isScan,
            [classes.addressScan]: isScan
          })}
        >
          <Email className={classes.icon} />
          <span className={classes.text}>{SUPPORT_EMAIL}</span>
        </div>
        <div className={classes.hotLine}>
          <Phone className={classes.icon} />
          <span className={classes.text}>{SUPPORT_PHONE}</span>
        </div>
      </div>
    </footer>
  )
}

ActivateFooter.propTypes = {
  position: PropTypes.oneOf(['inherit', 'absolute', 'fixed', 'relative', 'static', 'sticky']),
  border: PropTypes.bool,
  isScan: PropTypes.bool
}

export default ActivateFooter
