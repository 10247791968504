import PropTypes from 'prop-types'

import { createContext, useState } from 'react'

export const AppContext = createContext()

export const AppProvider = ({ children }) => {
  const [loadingInit, setLoadingInit] = useState(false)

  return <AppContext.Provider value={{ loadingInit, setLoadingInit }}>{children}</AppContext.Provider>
}
AppProvider.propTypes = {
  children: PropTypes.node
}

export default AppProvider
