/**
 * @desc: StaticDateRangePicker Component
 * @author: OurTime...
 * @date: 2021/1/8
 */
import classesNames from 'clsx'
import { isEqual } from 'lodash'
import PropTypes from 'prop-types'

import { useCallback, useMemo, useState } from 'react'

import { withStyles } from '@material-ui/core/styles'

import { cancelButton, fontRegular, successColor } from '../../Common/jss'
import Button from '../CustomButtons/Button'
import DateRangePickerDay from './DateRangePickerDay'
import { useNextMonthDisabled, usePreviousMonthDisabled } from './Hooks/date-helpers-hooks'
import { useUtils } from './Hooks/useUtils'
import PickersArrowSwitcher from './PickersArrowSwitcher'
import PickersCalendar from './PickersCalendar'
import { defaultMaxDate, defaultMinDate, doNothing, setQuickRangeLists } from './Utils'
import { calculateRangePreview } from './Utils/date-range-manager'
import { isEndOfRange, isStartOfRange, isWithinRange } from './Utils/date-utils'

const styles = () => ({
  root: {
    display: 'flex',
    flexDirection: 'row'
  },
  speedyEntrance: {
    width: '140px',
    borderRight: '1px solid #EBEBEB',
    padding: '20px'
  },
  rangeCalendarContainer: {
    paddingLeft: 6,
    paddingRight: 6,
    '&:not(:last-child)': {
      borderRight: '1px solid #EBEBEB'
    }
  },
  calendar: {
    minWidth: 312,
    minHeight: 288
  },
  arrowSwitcher: {
    padding: '16px 0 8px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  btn: {
    ...fontRegular,
    marginBottom: 6,
    '&, &:hover, &:active, &:focus': {
      background: 'transparent',
      color: '#6D6E71',
      boxShadow: 'none'
    },
    justifyContent: 'left'
  },
  checked: {
    '&, &:hover, &:active, &:focus': {
      color: successColor[0]
    }
  },
  pickerWrapper: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column'
  },
  pickerContent: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row'
  },
  pickerHandle: {
    borderTop: '1px solid #EBEBEB',
    paddingTop: '8px',
    paddingBottom: '6px',
    textAlign: 'right'
  },
  handleBtn: {
    marginRight: '20px'
  },
  cancelButton: {
    ...cancelButton
  }
})

function getCalendarsArray(calendars) {
  switch (calendars) {
    case 1:
      return [0]
    case 2:
      return [0, 0]
    case 3:
      return [0, 0, 0]
    default:
      return new Array(calendars).fill(0)
  }
}

const StaticDateRangePicker = props => {
  const {
    calendars = 2,
    changeMonth,
    classes,
    components,
    componentsProps,
    currentlySelectingRangeEnd,
    currentMonth,
    date,
    disableFuture,
    disablePast,
    leftArrowButtonText = 'Previous month',
    maxDate: maxDateProp,
    minDate: minDateProp,
    onChange,
    renderDay = (_, dateRangeProps) => <DateRangePickerDay {...dateRangeProps} />,
    rightArrowButtonText = 'Next month',
    speedyLists = setQuickRangeLists(),
    cancelHandle,
    confirmHandle,
    quickRangeChange,
    ...other
  } = props

  const utils = useUtils()
  const minDate = minDateProp || utils.date(defaultMinDate)
  const maxDate = maxDateProp || utils.date(defaultMaxDate)
  const [rangePreviewDay, setRangePreviewDay] = useState(null)

  const isNextMonthDisabled = useNextMonthDisabled(currentMonth, {
    disableFuture,
    maxDate
  })
  const isPreviousMonthDisabled = usePreviousMonthDisabled(currentMonth, {
    disablePast,
    minDate
  })

  const previewingRange = calculateRangePreview({
    utils,
    range: date,
    newDate: rangePreviewDay,
    currentlySelectingRangeEnd
  })

  const handleDayChange = useCallback(
    day => {
      setRangePreviewDay(null)
      onChange(day)
    },
    [onChange]
  )

  const handlePreviewDayChange = newPreviewRequest => {
    if (!isWithinRange(utils, newPreviewRequest, date)) {
      setRangePreviewDay(newPreviewRequest)
    } else {
      setRangePreviewDay(null)
    }
  }

  const CalendarTransitionProps = useMemo(
    () => ({
      onMouseLeave: () => setRangePreviewDay(null)
    }),
    []
  )

  const selectNextMonth = useCallback(() => {
    changeMonth(utils.getNextMonth(currentMonth))
  }, [changeMonth, currentMonth, utils])

  const selectPreviousMonth = useCallback(() => {
    changeMonth(utils.getPreviousMonth(currentMonth))
  }, [changeMonth, currentMonth, utils])

  const quickRangeClickedHandle = rangeInfo => {
    const { values } = rangeInfo
    if (values && quickRangeChange) {
      quickRangeChange(values)
      confirmHandle(values)
    }
  }

  const isDisabled = () => {
    const isTrue = !date[0] && !date[1]
    console.log(`isTrue`, isTrue)
    return isTrue
  }

  console.log(date)
  return (
    <div className={classes.root}>
      {speedyLists && (
        <div className={classes.speedyEntrance}>
          {speedyLists.map((item, index) => {
            return (
              <Button
                key={`${item}-${index}`}
                size={'sm'}
                className={classesNames({
                  [classes.btn]: true,
                  [classes.checked]: isEqual(item.values, date)
                })}
                fullWidth
                onClick={() => quickRangeClickedHandle(item)}
              >
                {item.label}
              </Button>
            )
          })}
        </div>
      )}
      <div className={classes.pickerWrapper}>
        <div className={classes.pickerContent}>
          {getCalendarsArray(calendars).map((_, index) => {
            const monthOnIteration = utils.setMonth(currentMonth, utils.getMonth(currentMonth) + index)
            return (
              <div
                key={index}
                className={classes.rangeCalendarContainer}
              >
                <PickersArrowSwitcher
                  className={classes.arrowSwitcher}
                  components={components}
                  componentsProps={componentsProps}
                  isLeftDisabled={isPreviousMonthDisabled}
                  isLeftHidden={index !== 0}
                  isRightDisabled={isNextMonthDisabled}
                  isRightHidden={index !== calendars - 1}
                  leftArrowButtonText={leftArrowButtonText}
                  rightArrowButtonText={rightArrowButtonText}
                  onLeftClick={selectPreviousMonth}
                  onRightClick={selectNextMonth}
                >
                  {utils.format(monthOnIteration, 'monthAndYear')}
                </PickersArrowSwitcher>
                {/* eslint-disable-next-line react/react-in-jsx-scope */}
                <PickersCalendar
                  {...other}
                  key={index}
                  className={classes.calendar}
                  currentMonth={monthOnIteration}
                  date={date}
                  TransitionProps={CalendarTransitionProps}
                  renderDay={(day, __, DayProps) =>
                    renderDay(day, {
                      isPreviewing: isWithinRange(utils, day, previewingRange),
                      isStartOfPreviewing: isStartOfRange(utils, day, previewingRange),
                      isEndOfPreviewing: isEndOfRange(utils, day, previewingRange),
                      isHighlighting: isWithinRange(utils, day, date),
                      isStartOfHighlighting: isStartOfRange(utils, day, date),
                      isEndOfHighlighting: isEndOfRange(utils, day, date),
                      onMouseEnter: () => handlePreviewDayChange(day),
                      ...DayProps
                    })
                  }
                  onChange={handleDayChange}
                  onFocusedDayChange={doNothing}
                />
              </div>
            )
          })}
        </div>
        <div className={classes.pickerHandle}>
          <Button
            size={'sm'}
            className={classesNames({
              [classes.handleBtn]: true,
              [classes.cancelButton]: true
            })}
            onClick={cancelHandle}
          >
            {'Cancel'}
          </Button>
          <Button
            className={classes.handleBtn}
            color={'success'}
            disabled={isDisabled()}
            size={'sm'}
            onClick={() => {
              if (date && quickRangeChange) {
                quickRangeChange(date)
                confirmHandle(date)
              }
            }}
          >
            {'Apply'}
          </Button>
        </div>
      </div>
    </div>
  )
}

StaticDateRangePicker.propTypes = {
  calendars: PropTypes.number,
  changeMonth: PropTypes.func,
  classes: PropTypes.object,
  components: PropTypes.node,
  componentsProps: PropTypes.object,
  currentlySelectingRangeEnd: PropTypes.object,
  currentMonth: PropTypes.number,
  date: PropTypes.object,
  disableFuture: PropTypes.bool,
  disablePast: PropTypes.bool,
  leftArrowButtonText: PropTypes.string,
  maxDate: PropTypes.object,
  minDate: PropTypes.object,
  onChange: PropTypes.func,
  renderDay: PropTypes.object,
  rightArrowButtonText: PropTypes.string,
  speedyLists: PropTypes.array,
  cancelHandle: PropTypes.func,
  confirmHandle: PropTypes.func,
  quickRangeChange: PropTypes.object
}

export default withStyles(styles, { name: 'CustomStaticDateRangePicker' })(StaticDateRangePicker)
