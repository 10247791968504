import { Base64 } from 'js-base64'

export const admins = ['SuperAdmin', 'Admin', 'Owner']

export const digit6Tips = 'Password must be a 6-digit number'

export const longDescribeText =
  'If you lose connection to the network due to configuration error, your last working network will be automatically restored.'

export const offlineText = 'Please ensure the device is online to receive an update.'

export const lowOsVersionCannotUpdateAppText = 'OS version is too low, please upgrade OS first.'

export const getNewQueryParams = (queryParams, valueObj) => {
  const queryParamsSource = JSON.parse(Base64.decode(queryParams))
  const newValues = {
    ...queryParamsSource,
    ...valueObj
  }
  return newValues
}
