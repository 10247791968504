import classNames from 'clsx'
import { Base64 } from 'js-base64'
import PropTypes from 'prop-types'

import Button from '@material-ui/core/Button'
// import { ExpandLess, ExpandMore } from '@material-ui/icons'
import Chip from '@material-ui/core/Chip'
// @material-ui/core
import ListItem from '@material-ui/core/ListItem'
import { ExpandLess, ExpandMore } from '@material-ui/icons'

import { useStore } from '@Hooks/useStore'

const SidebarCategory = props => {
  const {
    name,
    // icon,
    classes,
    badge,
    component,
    path,
    isCollapsable,
    isOpen,
    ...rest
  } = props
  const {
    route: { queryParams }
  } = useStore()
  const activeRoute = routeName => {
    const { from } = queryParams ? JSON.parse(Base64.decode(queryParams)) : { form: '' }
    const name = routeName && routeName.includes('/') ? routeName.split('/')[1] : ''

    if (name === from && window.location.href.includes('detail')) {
      return 'active'
    }
    return window.location.pathname.indexOf(routeName) === 0 ? 'active' : ''
  }

  const navLinkClasses = classNames({
    [classes.button]: true,
    [classes.node]: isCollapsable,
    [classes.active]: !isCollapsable && activeRoute(path)
  })
  const NameBetaDisplay = props => {
    const { name } = props
    return name === 'Support' ? <span className={classes.beta}>&nbsp;{`(BETA)`}</span> : null
  }
  return (
    <ListItem
      className={classes.item}
      disableGutters
      {...rest}
    >
      <Button
        className={navLinkClasses}
        component={component}
        to={path}
      >
        {/*<div className={classes.icon}>{icon}</div>*/}
        {name}
        <NameBetaDisplay name={name} />
        {isCollapsable ? isOpen ? <ExpandMore /> : <ExpandLess /> : null}
        {badge ? <Chip label={badge} /> : ''}
      </Button>
    </ListItem>
  )
}

SidebarCategory.propTypes = {
  name: PropTypes.string,
  classes: PropTypes.object,
  badge: PropTypes.node,
  component: PropTypes.any,
  path: PropTypes.string,
  isCollapsable: PropTypes.bool,
  isOpen: PropTypes.bool
}

export default SidebarCategory
