/**
 * @desc: PickersCalendar Component
 * @author: OurTime...
 * @date: 2021/1/7
 */
import PropTypes from 'prop-types'

import { Fragment, useCallback } from 'react'

import { useTheme } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'

import { DAY_MARGIN, DAY_SIZE } from '../../Utils/constants'
import Loading from '../Loading/Loading'
import Typography from '../Typography/Typography'
import { keycode, useGlobalKeyDown } from './Hooks/useKeyDown'
import { useNow, useUtils } from './Hooks/useUtils'
import PickersDay from './PickersDay'

const weeksContainerHeight = (DAY_SIZE + DAY_MARGIN * 4) * 6
const styles = theme => ({
  root: {
    minHeight: weeksContainerHeight
  },
  loadingContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    minHeight: weeksContainerHeight
  },
  weekContainer: {
    overflow: 'hidden'
  },
  week: {
    margin: `${DAY_MARGIN}px 0`,
    display: 'flex',
    justifyContent: 'center'
  },
  daysHeader: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  weekDayLabel: {
    width: 28,
    height: 40,
    margin: '0 2px',
    textAlign: 'center',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    color: theme.palette.text.secondary
  }
})

const PickersCalendar = props => {
  const {
    allowSameDateSelection,
    allowKeyboardControl,
    classes,
    loading,
    date,
    isDateDisabled = day => !!day,
    isMonthSwitchingAnimating,
    disableHighlightToday,
    onChange,
    renderDay,
    renderLoading = () => <Loading />,
    showDaysOutsideCurrentMonth,
    currentMonth,
    focusedDay,
    onFocusedDayChange: changeFocusedDay
  } = props
  const now = useNow()
  const utils = useUtils()
  const theme = useTheme()

  const handleDaySelect = useCallback(
    (day, isFinish = 'finish') => {
      const finalDate = Array.isArray(date) ? day : utils.mergeDateAndTime(day, date || now)
      onChange(finalDate, isFinish)
    },
    [date, now, onChange, utils]
  )

  const initialDate = Array.isArray(date) ? date[0] : date

  const nowFocusedDay = focusedDay || initialDate || now
  useGlobalKeyDown(Boolean(allowKeyboardControl), {
    [keycode.ArrowUp]: () => changeFocusedDay(utils.addDays(nowFocusedDay, -7)),
    [keycode.ArrowDown]: () => changeFocusedDay(utils.addDays(nowFocusedDay, 7)),
    [keycode.ArrowLeft]: () => changeFocusedDay(utils.addDays(nowFocusedDay, theme.direction === 'ltr' ? -1 : 1)),
    [keycode.ArrowRight]: () => changeFocusedDay(utils.addDays(nowFocusedDay, theme.direction === 'ltr' ? 1 : -1)),
    [keycode.Home]: () => changeFocusedDay(utils.startOfWeek(nowFocusedDay)),
    [keycode.End]: () => changeFocusedDay(utils.endOfWeek(nowFocusedDay)),
    [keycode.PageUp]: () => changeFocusedDay(utils.getNextMonth(nowFocusedDay)),
    [keycode.PageDown]: () => changeFocusedDay(utils.getPreviousMonth(nowFocusedDay))
  })
  // const currentMonth = utils.startOfMonth(now)
  const currentMonthNumber = utils.getMonth(currentMonth)
  const selectedDates = (Array.isArray(date) ? date : [date])
    .filter(Boolean)
    .map(selectedDateItem => selectedDateItem && utils.startOfDay(selectedDateItem))

  return (
    <Fragment>
      <div className={classes.daysHeader}>
        {utils &&
          utils.getWeekdays().map((day, i) => (
            <Typography
              key={day + i.toString()}
              className={classes.weekDayLabel}
              variant="caption"
              aria-hidden
            >
              {day}
            </Typography>
          ))}
      </div>
      {loading ? (
        <div className={classes.loadingContainer}>{renderLoading()}</div>
      ) : (
        <div
          className={classes.weekContainer}
          data-mui-test="pickers-calendar"
          role="grid"
        >
          {utils.getWeekArray(currentMonth).map(week => (
            <div
              key={`week-${week[0]}`}
              className={classes.week}
              role="row"
            >
              {week.map(day => {
                const dayProps = {
                  key: day.toString(),
                  day,
                  role: 'cell',
                  isAnimating: isMonthSwitchingAnimating,
                  disabled: isDateDisabled(day),
                  allowKeyboardControl,
                  allowSameDateSelection,
                  focused: allowKeyboardControl && Boolean(focusedDay) && utils.isSameDay(day, nowFocusedDay),
                  today: utils.isSameDay(day, now),
                  outsideCurrentMonth: utils.getMonth(day) !== currentMonthNumber,
                  selected: selectedDates.some(selectedDate => selectedDate && utils.isSameDay(selectedDate, day)),
                  disableHighlightToday,
                  showDaysOutsideCurrentMonth,
                  focusable:
                    allowKeyboardControl &&
                    Boolean(nowFocusedDay) &&
                    utils.toJsDate(nowFocusedDay).getDate() === utils.toJsDate(day).getDate(),
                  onDayFocus: changeFocusedDay,
                  onDaySelect: handleDaySelect
                }

                return renderDay ? renderDay(day, selectedDates, dayProps) : <PickersDay {...dayProps} />
              })}
            </div>
          ))}
        </div>
      )}
    </Fragment>
  )
}

PickersCalendar.propTypes = {
  calendars: PropTypes.number,
  allowSameDateSelection: PropTypes.bool,
  allowKeyboardControl: PropTypes.bool,
  classes: PropTypes.object,
  loading: PropTypes.bool,
  date: PropTypes.object,
  isDateDisabled: PropTypes.bool,
  isMonthSwitchingAnimating: PropTypes.bool,
  disableHighlightToday: PropTypes.bool,
  onChange: PropTypes.func,
  renderDay: PropTypes.node,
  renderLoading: PropTypes.node,
  showDaysOutsideCurrentMonth: PropTypes.bool,
  currentMonth: PropTypes.number,
  focusedDay: PropTypes.bool,
  onFocusedDayChange: PropTypes.func
}

export default withStyles(styles, { name: 'CustomPickersCalendar' })(PickersCalendar)
