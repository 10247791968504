import PropTypes from 'prop-types'

import DialogContent from 'src/Components/DialogContent/DialogContent'

const SalesRestoreDialog = ({ closeHandle, confirmHandle, ...rest }) => {
  return (
    <DialogContent
      closeHandle={closeHandle}
      confirmHandle={confirmHandle}
      dialogContent={'Are you sure you want to restart the demo?'}
      dialogTitle={'Restart the Demo'}
      cancel
      {...rest}
    />
  )
}

SalesRestoreDialog.propTypes = {
  open: PropTypes.bool,
  closeHandle: PropTypes.func,
  confirmHandle: PropTypes.func
}

export const SalesResetAgreementDialog = ({ closeHandle, confirmHandle, ...rest }) => {
  return (
    <DialogContent
      closeHandle={closeHandle}
      confirmHandle={confirmHandle}
      dialogContent={'Are you sure you want to Reset Partner Agreement?'}
      dialogTitle={'Reset Partner Agreement'}
      cancel
      {...rest}
    />
  )
}

SalesResetAgreementDialog.propTypes = {
  open: PropTypes.bool,
  closeHandle: PropTypes.func,
  confirmHandle: PropTypes.func
}

export default SalesRestoreDialog
